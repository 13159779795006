/* eslint-disable consistent-return */
import { useQueryClient } from '@tanstack/react-query';
import { useGqlMutation, useGqlQuery } from '../useGqlQuery';
import { connect } from '../../utils/helper';

import { CREATE_TASK, GET_TASK, UPDATE_TASK } from './gqlTasks';

export const useTask = (identifier, config = {}) => {
  const { isLoading, isError, data } = useGqlQuery(
    ['task', identifier],
    GET_TASK,
    {
      id: identifier,
    },
    config,
  );
  const task = data?.task;
  return { isLoading, isError, task };
};

export const useCreateTask = () => {
  const mutation = useGqlMutation(['createTask'], CREATE_TASK);

  const createTask = async (task, options = {}) => {
    const { client } = task;

    // Task and client.id is required, don't execute mutation when emitted
    if (!(task && client?.id)) return;

    // Extract required fields for mutation
    const { id, isNew, project, ...input } = task;

    return mutation.mutateAsync(
      {
        input: {
          ...input,
          client: connect(client.id),
          project: connect(project?.id),
        },
      },
      options,
    );
  };

  return createTask;
};

export const useUpdateTask = () => {
  const queryClient = useQueryClient();
  const mutation = useGqlMutation(['updateTask'], UPDATE_TASK, {
    onSettled: ({ updateTask }) => {
      queryClient.invalidateQueries({ queryKey: ['task', updateTask.id] });
    },
  });

  const updateTask = async (task, options = {}) => {
    const { client } = task;

    // Task.id and client.id is required, don't execute mutation when emitted
    if (!(task.id && client?.id)) return;

    // Extract required fields for mutation
    const { project, timelogs, ...input } = task;

    return mutation.mutateAsync(
      {
        input: {
          ...input,
          client: connect(client.id),
          project: connect(project?.id),
        },
      },
      options,
    );
  };

  return updateTask;
};
