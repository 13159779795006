import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Center, HStack, IconButton, Text, VStack } from '@chakra-ui/react';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import { SingleDatepicker } from 'chakra-dayzed-datepicker';

import { useStore } from '../../store';

import { MonthNamesShort, WeekdayNamesShort } from '../../utils/calendar';
import Time from '../../utils/time';

export default function DailyReportStatusBar({ isFetching }) {
  const { date, registeredTime, setDate } = useStore((state) => ({
    date: dayjs(state.date).toDate(),
    registeredTime: state.registeredTime,
    setDate: state.setDate,
  }));

  const previousDate = () => {
    const previous = dayjs(date).subtract(1, 'day');
    setDate(previous.toDate());
  };

  const nextDate = () => {
    const next = dayjs(date).add(1, 'day');
    setDate(next.toDate());
  };

  const progress = !isFetching ? Time.getPercentageBasedOnWorkingHours(registeredTime) : 0;
  const time = Time.getFormattedTimeFromMinutes(!isFetching ? registeredTime : 0);

  return (
    <Center
      position="relative"
      zIndex={1}
      padding={3}
      backgroundColor="blue.800"
      minH="65px"
      color="white"
      _after={{
        content: '""',
        position: 'absolute',
        zIndex: -1,
        left: 0,
        width: `${progress}%`,
        height: 'full',
        backgroundColor: 'blue.500',
      }}
    >
      <HStack width="full" spacing={2}>
        <IconButton
          onClick={previousDate}
          aria-label="previous day"
          variant="ghost"
          icon={<FiArrowLeft size={18} />}
        />
        <Center flex={1}>
          <VStack spacing={0}>
            <SingleDatepicker
              usePortal
              propsConfigs={{
                dayOfMonthBtnProps: {
                  selectedBg: 'gray.300',
                  borderColor: 'gray.200',
                  _hover: {
                    bg: 'gray.200',
                  },
                },
                inputProps: {
                  cursor: 'pointer',
                  variant: 'unstyled',
                  fontSize: 'sm',
                  textAlign: 'center',
                },
              }}
              configs={{
                dateFormat: 'EEEE - dd/MM',
                monthNames: MonthNamesShort,
                dayNames: WeekdayNamesShort,
              }}
              name="date"
              date={date}
              onDateChange={(selectedDate) => setDate(selectedDate)}
            />
            <Text fontSize="xs">{time} / 8:00</Text>
          </VStack>
        </Center>
        <IconButton
          onClick={nextDate}
          aria-label="previous day"
          variant="ghost"
          icon={<FiArrowRight size={18} />}
        />
      </HStack>
    </Center>
  );
}

DailyReportStatusBar.propTypes = {
  isFetching: PropTypes.bool,
};
