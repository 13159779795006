import React from 'react';
import PropTypes from 'prop-types';
import {
  Alert as ChakraAlert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
} from '@chakra-ui/react';

export default function Alert({ title, children, showIcon }) {
  return (
    <ChakraAlert status="error" borderRadius="md" variant="left-accent">
      {!!showIcon && <AlertIcon alignSelf="flex-start" />}

      <Box>
        {!!title && <AlertTitle>{title}</AlertTitle>}
        <AlertDescription>{children}</AlertDescription>
      </Box>
    </ChakraAlert>
  );
}

Alert.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  showIcon: PropTypes.bool,
};

Alert.defaultProps = {
  showIcon: true,
};
