import React from 'react';
import PropTypes from 'prop-types';
import { Center, Text } from '@chakra-ui/react';

export default function NotFound({ label }) {
  return (
    <Center>
      <Text textAlign="center" casing="uppercase" fontSize="sm" color="gray.400">
        {label}
      </Text>
    </Center>
  );
}

NotFound.propTypes = {
  label: PropTypes.string,
};

NotFound.defaultProsp = {
  label: 'Niet gevonden',
};
