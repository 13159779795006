import { useStore } from '../store';
import { usePlanningQuery } from '../api/users';

export const PLANNING_PLACEHOLDER = 'Planning placeholder';

export function usePlanning() {
  const { date } = useStore((state) => ({
    date: state.getFormattedDate(),
  }));

  const { planning } = usePlanningQuery(date);

  const findTaskInPlanning = (taskId) => planning.find(({ id }) => id === taskId);

  const getPlanningHoursByTask = (taskId) => {
    const task = findTaskInPlanning(taskId);
    return task ? task.hours : 0;
  };

  return {
    planning,
    findTaskInPlanning,
    getPlanningHoursByTask,
  };
}
