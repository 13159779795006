import dayjs from 'dayjs';

export default class Date {
  static getFormattedDate(date = new Date(), format = 'YYYY-MM-DD') {
    return dayjs(date).format(format);
  }

  static getFormattedDateYearMonthDay(date = new Date()) {
    return this.getFormattedDate(date, 'YYYY-MM-DD');
  }

  static getShortMonthAndDay(date) {
    return this.getFormattedDate(date, 'D MMM');
  }
}
