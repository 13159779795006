/* eslint-disable prefer-destructuring */
import React from 'react';
import PropTypes from 'prop-types';
import { HStack, Text } from '@chakra-ui/react';
import { FiClock } from 'react-icons/fi';

import { useBudgetStatus } from '../../hooks/useBudgetStatus';

import Time from '../../utils/time';

export default function TimeBudget({ progress, budget }) {
  const { getBackgroundColor, getTextColor } = useBudgetStatus(progress, budget);

  if (budget <= 0) {
    return null;
  }

  return (
    <HStack
      backgroundColor={getBackgroundColor}
      color={getTextColor}
      borderRadius={5}
      paddingX={2}
      paddingY={1}
    >
      <FiClock />
      <Text>
        {Time.getFormattedTimeFromMinutes(progress)} / {Time.getFormattedTimeFromMinutes(budget)}
      </Text>
    </HStack>
  );
}

TimeBudget.propTypes = {
  progress: PropTypes.number,
  budget: PropTypes.number,
};

TimeBudget.defaultProps = {
  progress: 0,
  budget: 0,
};
