import Date from '../utils/date';

const createUnspecifiedTimelogsSlice = (set) => ({
  unspecifiedTimelogs: [],
  updateUnspecifiedTimelogs: () =>
    set((state) => {
      const formattedDate = Date.getFormattedDateYearMonthDay(state.date);

      // Tijdelijke taken
      const newDateTimelogs = state.timelogs.filter((item) => !item.task || !item.isRemote);

      return {
        unspecifiedTimelogs: {
          ...state.unspecifiedTimelogs,
          ...{ [formattedDate]: newDateTimelogs },
        },
      };
    }),
});

export default createUnspecifiedTimelogsSlice;
