import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Box } from '@chakra-ui/react';
import { useIsMutating } from '@tanstack/react-query';

import { useStore } from '../../store';
import { useBudgetLimit } from '../../hooks/useBudgetLimit';

import BudgetLimitAlert from '../alert/BudgetLimitAlert';

export default function SaveButton({ onSubmit }) {
  const [showBudgetAlert, setShowBudgetAlert] = useState(false);

  const isMutating = useIsMutating({
    predicate: (mutation) => mutation.state.status === 'loading',
  });

  const { task, budget } = useStore((state) => ({
    task: state.task,
    budget: state.budget,
  }));
  const isLoading = !task || isMutating;

  const { isOutOfBudget } = useBudgetLimit();

  const handleSubmit = (ev) => {
    ev.preventDefault();
    onSubmit();
  };

  useEffect(() => {
    setShowBudgetAlert(isOutOfBudget);
  }, [budget]);

  return (
    <>
      {showBudgetAlert && (
        <Box p={8} pb={0}>
          <BudgetLimitAlert />
        </Box>
      )}
      <ButtonGroup p={8} pt={0}>
        <Button
          flex={1}
          mt={4}
          colorScheme="blue"
          onClick={handleSubmit}
          loadingText="Opslaan"
          isLoading={!!isMutating}
          disabled={isLoading}
        >
          Opslaan
        </Button>
      </ButtonGroup>
    </>
  );
}

SaveButton.propTypes = {
  onSubmit: PropTypes.func,
};

SaveButton.defaultProps = {
  onSubmit: () => {},
};
