import { gql } from 'graphql-request';

import { FRAGMENT_TASK } from '../tasks/gqlTasks';

export const FRAGMENT_PROJECT = gql`
  fragment Project on Project {
    id
    name
    project_type_id
  }
`;

export const FRAGMENT_ALL_TASKS = gql`
  fragment AllTasks on Client {
    projects {
      ...Project
      tasks(withoutArchived: true) {
        ...Task
      }
    }
    tasks(withoutArchived: true) {
      ...Task
    }
  }
  ${FRAGMENT_TASK}
  ${FRAGMENT_PROJECT}
`;

export const GET_CLIENTS = gql`
  query GetClients($search: String, $first: Int!, $page: Int) {
    clients(search: $search, first: $first, page: $page) {
      data {
        id
        name
      }
      paginatorInfo {
        currentPage
        lastPage
      }
    }
  }
`;

export const GET_CLIENT_TASKS = gql`
  query GetClientTasks($id: ID!) {
    client(id: $id) {
      id
      name
      ...AllTasks
    }
  }
  ${FRAGMENT_ALL_TASKS}
`;
