import React from 'react';
import { Grid, GridItem, Progress, Text } from '@chakra-ui/react';

import { WorkWeekdayNamesShort } from '../../utils/calendar';

import { useStore } from '../../store';
import { useWeeklyTimelogs } from '../../api/timelogs';

import Time from '../../utils/time';

export default function WeeklyReportStatusBar() {
  const { date } = useStore((state) => ({
    date: state.getFormattedDate(),
  }));

  const { timelogs, isLoading } = useWeeklyTimelogs({ date });

  const renderProgressBar = (key, value = 0) => (
    <GridItem key={key}>
      <Progress height="30px" colorScheme="blue" value={value} backgroundColor="gray.200" />
    </GridItem>
  );

  const renderProgress = () => {
    // Create placeholder progress bars while loading
    if (isLoading) {
      return WorkWeekdayNamesShort.map((el) => renderProgressBar(el));
    }

    return timelogs.map(({ date, totalTime }) =>
      renderProgressBar(date, Time.getPercentageBasedOnWorkingHours(totalTime)),
    );
  };

  return (
    <Grid templateColumns="repeat(5, 1fr)" columnGap={1}>
      {WorkWeekdayNamesShort.map((el) => (
        <GridItem padding={1} key={el}>
          <Text fontSize="xs" textTransform="uppercase" align="center">
            {el}
          </Text>
        </GridItem>
      ))}
      {renderProgress()}
    </Grid>
  );
}
