import { gql } from 'graphql-request';
import { FRAGMENT_TASK } from '../tasks/gqlTasks';
import { FRAGMENT_TIMELOG, FRAGMENT_TIMELOG_TASK } from '../timelogs/gqlTimelogs';

export const GET_ME = gql`
  query me {
    me {
      id
      first_name
      last_name
      email
      github_name
      recent_tasks {
        ...Task
      }
    }
  }
  ${FRAGMENT_TASK}
`;

export const GET_REMOTE_TIMELOGS = gql`
  query GetRemoteTimelogs($date: Date, $fromDate: Date, $toDate: Date) {
    me {
      id
      timelogs(date: $date, fromDate: $fromDate, toDate: $toDate) {
        ...Timelog
        task {
          ...TimelogTask
        }
      }
    }
  }
  ${FRAGMENT_TIMELOG}
  ${FRAGMENT_TIMELOG_TASK}
`;

export const GET_PLANNING = gql`
  query GetPlanning($date: Date) {
    me {
      id
      planning(date: $date, withoutArchived: true) {
        ...Task
      }
    }
  }
  ${FRAGMENT_TASK}
`;

export const GET_USERS = gql`
  query users {
    users {
      id
      first_name
      last_name
    }
  }
`;

export const GET_RECENT_GITHUB_COMMITS = gql`
  query GetRecentCommits {
    recent_commits {
      id
      url: html_url
      message
      repository
    }
  }
`;
