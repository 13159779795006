import { useToast as useChakraToast } from '@chakra-ui/react';

import { capitalize } from '../utils/format';

export const useToast = () => {
  const toast = useChakraToast();

  const createToast = ({ type = 'success', description = '' }) => {
    toast({
      title: capitalize(type),
      description,
      status: type,
      position: 'bottom-right',
      isClosable: true,
    });
  };

  return createToast;
};
