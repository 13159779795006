import React from 'react';
import PropTypes from 'prop-types';
import { Alert, AlertDescription, AlertIcon, AlertTitle, Box } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { useQueryClient } from '@tanstack/react-query';
import Modal from './Modal';

import { useUpdateTimelog } from '../../api/timelogs';
import { useStore } from '../../store';

export default function RefreshTime({ innerRef, onReset, ...props }) {
  const queryClient = useQueryClient();
  const updateTimelog = useUpdateTimelog();

  const { getValues } = useFormContext();

  const { localTimelog } = useStore((state) => ({
    localTimelog: state.timelog,
  }));

  const onResetTime = () => {
    if (!localTimelog?.isRemote) {
      onReset();
      return;
    }

    const { timelog, task } = getValues();

    // Reset time
    const updatedTimelog = {
      task: {
        id: task.id,
      },
      timelog: {
        ...timelog,
        time: 0,
      },
    };

    updateTimelog(updatedTimelog, {
      onSuccess: ({ updateTimelog }) => {
        onReset();

        queryClient.invalidateQueries(['task', updateTimelog?.task?.id]);
      },
    });
  };

  return (
    <Modal
      title="Hernieuw tijdslot"
      confirmTitle="Hernieuw"
      size="lg"
      onConfirm={onResetTime}
      {...props}
    >
      <Alert status="warning">
        <AlertIcon />
        <Box>
          <AlertTitle>Weet je zeker dat je dit tijdslot wilt hernieuwen?</AlertTitle>
          <AlertDescription>Dit zorgt ervoor dat je je huidige timelog verliest.</AlertDescription>
        </Box>
      </Alert>
    </Modal>
  );
}

RefreshTime.propTypes = {
  innerRef: PropTypes.object,
  onReset: PropTypes.func,
};

RefreshTime.defaultProps = {
  onReset: () => {},
};
