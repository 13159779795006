import React from 'react';
import PropTypes from 'prop-types';
import { HStack, Text } from '@chakra-ui/react';

import Digit from './Digit';

export default function Timer({ seconds, minutes, hours, onChange, onBlur, isRunning, disabled }) {
  return (
    <HStack>
      <Digit
        value={hours}
        name="hours"
        onChange={(value) => onChange('hours', value)}
        onBlur={(value) => onBlur('hours', value)}
        isRunning={isRunning}
        disabled={disabled}
      />
      <Text fontSize="3vw">:</Text>
      <Digit
        value={minutes}
        name="minutes"
        onChange={(value) => onChange('minutes', value)}
        onBlur={(value) => onBlur('minutes', value)}
        isRunning={isRunning}
        min="0"
        max="59"
        disabled={disabled}
      />
      <Text fontSize="3vw">:</Text>
      <Digit
        value={seconds}
        name="seconds"
        onChange={(value) => onChange('seconds', value)}
        onBlur={(value) => onBlur('seconds', value)}
        isRunning={isRunning}
        min="0"
        max="59"
        disabled={disabled}
      />
    </HStack>
  );
}

Timer.propTypes = {
  seconds: PropTypes.number.isRequired,
  minutes: PropTypes.number.isRequired,
  hours: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  isRunning: PropTypes.bool,
  disabled: PropTypes.bool,
};

Timer.defaultProps = {
  onChange: () => {},
  onBlur: () => {},
};
