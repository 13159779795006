import React from 'react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';
import { FormControl, FormLabel, Select } from '@chakra-ui/react';

export default function ControlledMultiSelect({
  control,
  name,
  id,
  label,
  rules,
  placeholder,
  children,
  ...props
}) {
  const {
    field: { onChange, onBlur, ref },
  } = useController({
    name,
    control,
    rules,
  });

  return (
    <FormControl id={id}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <Select
        ref={ref}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        multiple
        icon={<></>}
        height="xs"
        style={{ padding: 0 }}
        {...props}
      >
        {children}
      </Select>
    </FormControl>
  );
}

ControlledMultiSelect.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  rules: PropTypes.object,
  children: PropTypes.any,
  placeholder: PropTypes.string,
};
