import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import {
  Flex,
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
  InputRightAddon,
  Textarea,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { FiExternalLink, FiHelpCircle } from 'react-icons/fi';

import TimeBudget from '../budget/TimeBudget';
import TagList from '../tag-list/TagList';
import MultiCopySelect from '../select/MutliCopySelect';

import { PLANNING_PLACEHOLDER } from '../../hooks/usePlanning';
import HoursBundleBudget from '../budget/HoursBundleBudget';

export default function TaskDetailForm({ task }) {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const { client, is_hours_bundle } = task ?? {};
  const { hours_bundle, hours_bundle_used } = client ?? {};

  const subscribedAsanaUrl = watch('task.asana_url');

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setValue(name, value);
  };

  const onTagSelect = (tag) => {
    if (!tag) return;
    const name = watch('task.name');
    const tagPrefix = `${tag.toUpperCase()} | `;

    if (name) {
      const tagIndex = name?.indexOf('|');

      // Replace the old tag with the new tag
      if (tagIndex !== -1) {
        setValue('task.name', tagPrefix + name.slice(tagIndex + 2));
      } else {
        setValue('task.name', tagPrefix + name);
      }
    } else {
      setValue('task.name', tagPrefix);
    }
  };

  useEffect(() => {
    const formMap = [
      { name: 'task.name', value: task?.name ?? null },
      { name: 'task.description', value: task?.description },
      { name: 'task.asana_url', value: task?.asana_url },
      { name: 'task.status', value: task?.status },
    ];

    // init Set task detail form values
    formMap.forEach(({ name, value }) => setValue(name, value));
  }, [task]);

  return (
    <VStack height="full" spacing={4}>
      <FormControl>
        <InputGroup>
          <Input
            {...register('task.name', { required: true })}
            placeholder="Taaknaam"
            marginBottom={3}
            isInvalid={errors?.task?.name?.type === 'required'}
            onChange={handleChange}
          />
          <InputRightElement>
            <Tooltip
              label="Duidelijke naam, wees specifiek zodat ook je moeder het verstaat. En het ook binnen een jaar nog duidelijk is."
              placement="top-start"
              hasArrow
              p="3"
              color="blue.500"
              backgroundColor="gray.100"
              borderRadius="md"
              shadow="none"
            >
              <span>
                <FiHelpCircle />
              </span>
            </Tooltip>
          </InputRightElement>
        </InputGroup>
        <TagList onChange={onTagSelect} />
      </FormControl>
      <Textarea
        {...register('task.description')}
        placeholder="Omschrijving"
        flexGrow={1}
        resize="none"
        onChange={handleChange}
      />

      <MultiCopySelect
        name="timelogs"
        label="Timesheet"
        emptyLabel="Geen timelogs gevonden.."
        destination="task.description"
        data={task?.timelogs?.filter((item) => item.comments !== PLANNING_PLACEHOLDER)}
      />

      <InputGroup>
        <Input
          {...register('task.asana_url')}
          placeholder="Asana url"
          borderTopRightRadius={0}
          borderBottomRightRadius={0}
          onChange={handleChange}
          maxLength={255}
        />
        {subscribedAsanaUrl ? (
          <a href={subscribedAsanaUrl} target="_blank" rel="noreferrer">
            <InputRightAddon
              _hover={{
                background: 'gray.200',
                transitionDuration: '0.2s',
                transitionTimingFunction: 'ease-in-out',
              }}
            >
              <FiExternalLink size={13} />
            </InputRightAddon>
          </a>
        ) : (
          <InputRightAddon
            cursor="not-allowed"
            _hover={{
              background: 'gray.200',
              transitionDuration: '0.2s',
              transitionTimingFunction: 'ease-in-out',
            }}
          >
            <FiExternalLink size={13} />
          </InputRightAddon>
        )}
      </InputGroup>

      <Flex width="full" flexDirection="column" alignItems="flex-end" gap="2">
        <TimeBudget progress={task?.progress} budget={task?.budget_time} />
        {!!is_hours_bundle && (
          <HoursBundleBudget progress={hours_bundle_used} budget={hours_bundle} />
        )}
      </Flex>
    </VStack>
  );
}

TaskDetailForm.propTypes = {
  task: PropTypes.object,
};

TaskDetailForm.defaultProps = {
  task: {},
};
