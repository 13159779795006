import React from 'react';
import { Box } from '@chakra-ui/react';

import Time from '../../utils/time';
import { useStore } from '../../store';
import { useBudgetLimit } from '../../hooks/useBudgetLimit';

import Alert from './Alert';

export default function BudgetLimitAlert() {
  const { budget } = useStore((state) => ({
    budget: state.budget,
  }));

  const { isTaskOverBundleHours } = useBudgetLimit();

  const renderContent = () => {
    if (isTaskOverBundleHours) {
      return (
        <>
          Er zijn geen uren meer beschikbaar in de <strong>urenbundel</strong>.
        </>
      );
    }

    if (!budget) return null;

    return (
      <>
        Er is al{' '}
        <strong>{Time.getFormattedTimeFromMinutes(budget.current_used - budget.max)}</strong> over
        het huidige budget van <strong>{Time.getFormattedTimeFromMinutes(budget?.max)}</strong>{' '}
        geboekt.
      </>
    );
  };

  return (
    <Alert title="Budget is op!">
      <Box>{renderContent()}</Box>
      Ga langs bij de project manager om het budget te bespreken.
    </Alert>
  );
}
