import React from 'react';
import PropTypes from 'prop-types';

import Modal from './Modal';

export default function DeleteTime({ isOpen, onClose, onConfirm, isLoading }) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      title="Verwijder tijdslot"
      confirmTitle="Verwijder"
      colorScheme="red"
      isLoading={isLoading}
    >
      <p>Weet je zeker dat je dit tijdslot wilt verwijderen?</p>
    </Modal>
  );
}

DeleteTime.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  isLoading: PropTypes.bool,
};
