/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Center, HStack, IconButton, useDisclosure } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { FiPlayCircle, FiRefreshCw, FiStopCircle } from 'react-icons/fi';

import RefreshTimeModal from '../modal/RefreshTimeModal';
import Timer from '../time/Timer';
import BudgetLimitTimer from '../time/BudgetLimitTimer';

import Time from '../../utils/time';
import { useStore } from '../../store';

import useStopwatch from '../../hooks/useStopwatch';
import useUrlParams from '../../hooks/useUrlParams';
import { useBudgetLimit } from '../../hooks/useBudgetLimit';
import { useNotification } from '../../hooks/useNotification';

import { defaultFormValues } from '../../utils/form';

export default function TimeEntryInput({ onTriggerModal }) {
  const { timeParam } = useUrlParams();

  const {
    isOpen: refreshModalIsOpen,
    onOpen: onOpenRefreshModal,
    onClose: onCloseRefreshModal,
  } = useDisclosure();

  const [timerStopTimestamp, setTimerStopTimestamp] = useState(null);
  const [timerDisabled, setTimerDisabled] = useState(false);
  const [isManual, setIsManual] = useState(false);

  const { ignoreBudgetLimit, isBudgetCheckRequired } = useBudgetLimit();

  const [stopwatch, { start, pause, reset }] = useStopwatch({});
  const { hours, minutes, seconds, isRunning } = stopwatch;

  const [stopwatchBudgetLimitTimer, { start: startBudgetLimitTimer }] = useStopwatch({});
  const {
    hours: hoursBudgetLimitTimer,
    minutes: minutesBudgetLimitTimer,
    seconds: secondsBudgetLimitTimer,
    isRunning: isRunningBudgetLimitTimer,
  } = stopwatchBudgetLimitTimer;

  const {
    updateTimelog,
    activeTimelog,
    prependTimelog,
    storedTask,
    budget,
    timelog: currentTimelog,
  } = useStore((state) => ({
    updateTimelog: state.updateTimelog,
    activeTimelog: state.timelog,
    prependTimelog: state.prependTimelog,
    storedTask: state.task,
    budget: state.budget,
    unsetBudget: state.unsetBudget,
    timelog: state.timelog,
  }));
  const { client } = storedTask ?? {};

  const { isOutOfBudget } = useBudgetLimit();
  const notification = useNotification('Het budget is op! Jouw tijd loopt niet meer.', {
    ignore: ignoreBudgetLimit, // Ignore the notification when the budget limit is ignored
  });

  const timerColor = isOutOfBudget ? 'red.400' : 'blue.800';
  const handleColor = isOutOfBudget ? 'red.400' : 'blue.400';

  const { getValues, reset: resetFormVal } = useFormContext();

  const performTimelogAction = (actionData, actionPrepend = !activeTimelog) => {
    if (actionPrepend) {
      prependTimelog({
        includeTask: !!getValues('task'),
        data: {
          ...actionData,
          comments: getValues('timelog.comments'),
          task: getValues('task') ?? null,
        },
      });
    } else {
      updateTimelog(actionData);
    }
  };

  const onTimerPause = (prependTimelog = true) => {
    pause();

    if (prependTimelog) {
      performTimelogAction({ data: { hours, minutes, seconds, client } });
    }
  };

  const handleTimerAction = (key, value, isBlur) => {
    setIsManual(true);

    const offset = {
      hours,
      minutes,
      seconds,
    };

    // if NaN, return early
    if (Number.isNaN(value)) return;

    offset[key] = value;

    if (isBlur) {
      const inputTime = Time.getMinutesFromTime(offset);
      const timeLeftBudget = budget?.current_left + budget?.timelog_start_value;

      if (inputTime > timeLeftBudget && budget?.max !== 0 && !ignoreBudgetLimit) {
        setTimerDisabled(true);
        onTriggerModal(
          'manual',
          Time.getTimeFromSeconds((inputTime - budget?.timelog_start_value) * 60),
        );

        const newOffset = Time.getTimeFromSeconds(currentTimelog?.time * 60);
        performTimelogAction(newOffset);
        reset(Time.getOffsetTimestamp(newOffset), false);
      }
    } else {
      performTimelogAction(offset);
      reset(Time.getOffsetTimestamp(offset), false);
    }
  };

  const handleTimerBlur = (key, value) => {
    pause();
    handleTimerAction(key, value, true);
  };

  const handleTimerChange = (key, value) => {
    handleTimerAction(key, value, false);
  };

  const handleTimerPauseStop = () => {
    setIsManual(false);

    const offset = {
      hours,
      minutes,
      seconds,
    };

    // reset form values if new timelog is started from play button
    if (!getValues('client')) {
      resetFormVal(defaultFormValues);
    }

    if (!isRunning) {
      performTimelogAction({ data: offset });
      start();

      return;
    }

    onTimerPause();
  };

  const setSecondaryTimer = () => {
    const currentDate = new Date();
    setTimerStopTimestamp(
      `${Time.getTwoDigitNumberFromValue(currentDate.getHours())}:${Time.getTwoDigitNumberFromValue(
        currentDate.getMinutes(),
      )}:${Time.getTwoDigitNumberFromValue(currentDate.getSeconds())}`,
    );

    startBudgetLimitTimer();
  };

  const handleTimelogChange = (timelog, previousTimelog) => {
    if (!timelog) {
      reset(timeParam ? Time.getOffsetTimestamp(timeParam) : 0, false);
      return;
    }

    if (timelog.id !== previousTimelog?.id) {
      if (previousTimelog) {
        performTimelogAction(
          {
            timelog: previousTimelog,
            data: { hours, minutes, seconds },
          },
          false,
        );
      }

      const { hours: h, minutes: m } = Time.getTimeFromMinutes(timelog.time);
      reset(
        Time.getOffsetTimestamp({
          hours: timelog.hours ?? h,
          minutes: timelog.minutes ?? m,
          seconds: timelog.seconds,
        }),
      );
    }
  };

  const handleBudgetCheck = () => {
    if (!isBudgetCheckRequired()) return;

    const currentTimeInMinutes = Time.getMinutesFromTime({ hours, minutes });
    const { current_left, timelog_start_value, max, has_budget } = budget ?? {};

    const hasReachedBudgetLimit = currentTimeInMinutes >= current_left + timelog_start_value;
    const isTimelogStartValueGreaterThanMax = timelog_start_value > max;

    if (isTimelogStartValueGreaterThanMax || hasReachedBudgetLimit || isOutOfBudget) {
      onTimerPause(false);
      setTimerDisabled(true);

      if (!isManual && has_budget) {
        setSecondaryTimer();
        notification.notify();
      }
    }
  };

  useEffect(() => {
    if (timeParam) {
      performTimelogAction({ data: { ...timeParam } });
      prependTimelog({ data: { ...timeParam } });
    }
  }, [timeParam]);

  useEffect(() => {
    const storeSubscription = useStore.subscribe(
      (state) => [state.timelog],
      ([timelog], [previousTimelog]) => {
        handleTimelogChange(timelog, previousTimelog);
      },
    );

    notification.close();
    handleBudgetCheck();

    return () => {
      setTimerStopTimestamp(null);
      setTimerDisabled(false);
      storeSubscription();
    };
  }, [budget, hours, minutes, seconds, timeParam]);

  return (
    <>
      <Center
        paddingY={{ base: 6, xl: 8 }}
        paddingX={{ base: 0, xl: 3 }}
        backgroundColor={timerColor}
        color="white"
      >
        <HStack width="full" spacing={{ base: 0, xl: 2 }}>
          <Box padding={{ base: 1, xl: 3 }}>
            <Box marginTop={4}>
              <IconButton
                aria-label="Refresh time"
                variant="ghost"
                disabled={!activeTimelog}
                onClick={onOpenRefreshModal}
                icon={<FiRefreshCw size={15} />}
                _hover={{ color: 'blue.800', bgColor: 'white' }}
              />
            </Box>
          </Box>
          <Box flexGrow={1}>
            <Timer
              hours={hours}
              minutes={minutes}
              seconds={seconds}
              onChange={handleTimerChange}
              onBlur={handleTimerBlur}
              isRunning={isRunning}
            />
          </Box>
          <Box padding={{ base: 1, xl: 3 }}>
            <Box marginTop={4}>
              <IconButton
                aria-label={isRunning ? 'Pause timer' : 'Start timer'}
                variant="ghost"
                onClick={handleTimerPauseStop}
                icon={isRunning ? <FiStopCircle size={18} /> : <FiPlayCircle size={18} />}
                backgroundColor={isRunning ? handleColor : ''}
                _hover={{ color: 'blue.800', bgColor: 'white' }}
                disabled={timerDisabled}
              />
            </Box>
          </Box>
        </HStack>
      </Center>

      {timerStopTimestamp && (
        <BudgetLimitTimer
          timerColor={timerColor}
          timerStopTimestamp={timerStopTimestamp}
          hours={hoursBudgetLimitTimer}
          minutes={minutesBudgetLimitTimer}
          seconds={secondsBudgetLimitTimer}
          isRunning={isRunningBudgetLimitTimer}
        />
      )}

      <RefreshTimeModal isOpen={refreshModalIsOpen} onClose={onCloseRefreshModal} onReset={reset} />
    </>
  );
}

TimeEntryInput.propTypes = {
  timelog: PropTypes.object,
  onTriggerModal: PropTypes.func,
};

TimeEntryInput.defaultProps = {
  timelog: {},
  onTriggerModal: () => {},
};
