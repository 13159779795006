// API connect helper
export function connect(field) {
  return field ? { connect: parseInt(field, 10) } : undefined;
}

// Create a unique ID
export const createUid = () =>
  Date.now().toString() + Math.floor(Math.random() * 1000000).toString();

// Convert ID to number
export const toNumber = (id) => (id ? parseInt(id, 10) : null);

// Calculate remainder
export const calculateRemainingHours = (total, completed) =>
  total - completed > 0 ? Math.round((total - completed) / 60) : 0;
