/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-restricted-syntax */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Flex } from '@chakra-ui/react';

import DailyReportPane from '../../components/pane/DailyReportPane';
import TimeTrackingPane from '../../components/pane/TimeTrackingPane';
import TaskDetailPane from '../../components/pane/TaskDetailPane';
import Modal from '../../components/modal/Modal';
import BudgetLimitModal from '../../components/modal/BudgetLimitModal';

import { useStore } from '../../store';
import { useCreateTimelog, useUpdateTimelog } from '../../api/timelogs';
import { useCreateTask, useUpdateTask } from '../../api/tasks';

import { useBudgetLimit } from '../../hooks/useBudgetLimit';
import useCombinedTimelogs from '../../hooks/useCombinedTimelogs';
import useUrlParams from '../../hooks/useUrlParams';
import { PLANNING_PLACEHOLDER, usePlanning } from '../../hooks/usePlanning';

import { defaultFormValues } from '../../utils/form';
import Time from '../../utils/time';

export default function Timer() {
  const {
    storedTimelog,
    setTimelog,
    unsetTimelog,
    deleteLocalTimelog,
    task,
    unsetTask,
    setBudget,
    budget,
  } = useStore((state) => ({
    storedTimelog: state.timelog,
    setTimelog: state.setTimelog,
    unsetTimelog: state.unsetTimelog,
    deleteLocalTimelog: state.deleteTimelog,
    task: state.task,
    unsetTask: state.unsetTask,
    setBudget: state.setBudget,
    budget: state.budget,
  }));

  const { timelogs, isFetching } = useCombinedTimelogs();

  const { getSearchParam, deleteSearchParam } = useUrlParams();
  const searchTaskID = getSearchParam('task_id') ?? null;

  const { ignoreBudgetLimit, isOutOfBudget } = useBudgetLimit();
  const { planning } = usePlanning();

  const createTimelog = useCreateTimelog();
  const updateTimelog = useUpdateTimelog();
  const createTask = useCreateTask();
  const updateTask = useUpdateTask();

  const [modal, setModal] = useState({
    open: false,
    manualOpen: false,
    task: null,
    client: null,
    budget: null,
    totalInputBudget: null,
  });

  const form = useForm({
    shouldFocusError: false,
    defaultValues: defaultFormValues,
  });

  const { handleSubmit, reset } = form;

  const onCreateTimelog = (timelog) => {
    createTimelog(timelog, {
      onSuccess: (res) => {
        // Delete local timelog
        if (!storedTimelog?.isRemote) {
          deleteLocalTimelog(storedTimelog?.id);
        }

        // Reset form
        unsetTask();
        unsetTimelog();
        reset(defaultFormValues);

        if (searchTaskID) {
          // Set param timelog active
          setTimelog({ ...res.createTimelog, isRemote: true, comments: '' });
        }
      },
    });
  };

  const onUpdateTimelog = (timelog) => {
    updateTimelog(timelog, {
      onSuccess: () => {
        // Reset form
        unsetTask();
        unsetTimelog();
        reset(defaultFormValues);
      },
    });
  };

  const onCreateTask = async (task) => {
    const { createTask: mutatedTask } = await createTask(task);
    return mutatedTask;
  };

  const onUpdateTask = async (task) => {
    const { updateTask: mutatedTask } = await updateTask(task);
    return mutatedTask;
  };

  const onTriggerModal = (type = 'normal', inputTime = null) => {
    if (ignoreBudgetLimit) {
      return;
    }

    setModal((prevState) => ({
      ...prevState,
      task: task?.name,
      budget: Time.getFormattedTimeFromMinutes(budget?.max),
    }));

    if (type === 'manual') {
      setModal((prevState) => ({
        ...prevState,
        manualOpen: true,
        totalInputBudget: Time.getFormattedTimeFromMinutes(Time.getMinutesFromTime(inputTime)),
      }));
    } else {
      setModal((prevState) => ({
        ...prevState,
        open: true,
      }));
    }
  };

  const onFormSubmit = async (formData) => {
    const {
      task: { budget_time, ...task },
      client,
      timelog,
    } = formData;

    // If budget is over limit, trigger modal and return early to prevent submitting timelog
    if (isOutOfBudget) {
      onTriggerModal('manual', timelog?.timer);
      return;
    }

    // Trigger budget modal when submitting timelog and over budget
    if (timelog && timelog?.timer) {
      // calculate the difference between starter time in timelog and end time timelog to know if the new timelog is over the minutes left in budget
      const differenceInMinutes = Time.getMinutesFromTime({
        hours: Number(timelog?.timer.hours),
        minutes: Number(timelog?.timer.minutes),
      });

      if (
        budget?.has_budget &&
        differenceInMinutes >= budget?.current_left + budget?.timelog_start_value &&
        differenceInMinutes >= budget?.timelog_start_value &&
        budget?.max !== 0
      ) {
        onTriggerModal();
      }
    }

    // If timelog isn't remote but local, create a timelog otherwise update the remote timelog
    const mutation = storedTimelog?.isRemote ? onUpdateTimelog : onCreateTimelog;

    // Create or update task
    if (task.isNew) {
      const createdTask = await onCreateTask({ ...task, client });
      if (!createdTask?.id) return;
      mutation({ timelog, task: createdTask });
    } else {
      const updatedTask = await onUpdateTask({ ...task, client });
      if (!updatedTask?.id) return;
      mutation({ timelog, task: updatedTask });
    }
  };

  const checkTimelogExists = (checkID) =>
    timelogs.some((timelog) => timelog.task && timelog.task.id === checkID);

  useEffect(() => {
    if (task && !task.isNew) {
      setBudget();

      setModal((prevState) => ({
        ...prevState,
        client: task?.client?.name,
      }));
    }
  }, [task]);

  useEffect(() => {
    planning.forEach((plan) => {
      const taskInTimelogs = checkTimelogExists(plan.id);

      if (!taskInTimelogs && !isFetching) {
        const timer = { hours: '0', minutes: '0', seconds: '0' };
        onCreateTimelog({
          timelog: { timer, comments: PLANNING_PLACEHOLDER },
          task: { ...plan },
        });
      }
    });
  }, [planning, isFetching]);

  useEffect(() => {
    const handleCreateTimelog = () => {
      const taskInTimelogs = checkTimelogExists(searchTaskID);

      if (!taskInTimelogs) {
        const timer = { hours: '0', minutes: '0', seconds: '0' };
        onCreateTimelog({
          timelog: { timer, comments: PLANNING_PLACEHOLDER },
          task: { id: searchTaskID },
        });
      } else {
        const findTimelog = timelogs.find(
          (timelog) => timelog.task && timelog.task.id === searchTaskID,
        );

        setTimeout(() => {
          // Set param timelog active
          setTimelog(findTimelog);
        }, 1000);
      }

      // Remove URL params
      deleteSearchParam('task_id');
    };

    if (searchTaskID && !isFetching) {
      handleCreateTimelog();
    }
  }, [searchTaskID, isFetching]);

  return (
    <>
      <FormProvider {...form}>
        <Flex minH="45rem" height="100vh" paddingY={3} as="form">
          <DailyReportPane />
          <TimeTrackingPane
            onFormSubmit={() => handleSubmit(onFormSubmit)()}
            onTriggerModal={onTriggerModal}
          />
          <TaskDetailPane />
        </Flex>
      </FormProvider>

      <BudgetLimitModal
        data={modal}
        isOpen={modal.manualOpen}
        onClose={() =>
          setModal((prevState) => ({
            ...prevState,
            manualOpen: false,
          }))
        }
      />

      <Modal
        isOpen={modal.open}
        onClose={() =>
          setModal((prevState) => ({
            ...prevState,
            open: false,
          }))
        }
        title="Let op!"
        colorScheme="red"
        showCancel={false}
      >
        <p>
          Dit was de laatste keer dat je op de taak: <strong>{modal?.task}</strong> van de klant:{' '}
          <strong>{modal?.client}</strong> kon boeken.
        </p>
        <br />
        <p>
          Het huidige budget van <strong>{modal?.budget}</strong> is bereikt of overschreden. Neem
          indien nodig contact op met de Project Manager om het budget te bespreken.
        </p>
      </Modal>
    </>
  );
}
