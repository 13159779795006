import { useEffect, useState } from 'react';

export default function useConfirmBrowserClose(show = false) {
  const [showPrompt, setShowPrompt] = useState(show);

  useEffect(() => {
    const onBeforeUnload = (e) => {
      if (showPrompt) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [showPrompt]);

  return [showPrompt, setShowPrompt];
}
