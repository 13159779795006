import { useEffect, useRef, useState } from 'react';

export const useNotification = (title, options) => {
  const [isPermissionGranted, setIsPermissionGranted] = useState(
    Notification?.permission === 'granted',
  );

  const notification = useRef(null);
  const shouldIgnore = options?.ignore;

  const notify = () => {
    if (isPermissionGranted && !shouldIgnore) {
      if (options?.ignore) {
        return;
      }

      notification.current = new Notification(title, options);
    }
  };

  const close = () => {
    if (!shouldIgnore) {
      notification.current?.close();
    }
  };

  useEffect(() => {
    if (!isPermissionGranted) {
      Notification.requestPermission().then((status) => {
        setIsPermissionGranted(status === 'granted');
      });
    }
  }, [isPermissionGranted]);

  return {
    notify,
    close,
  };
};
