import { useQueryClient } from '@tanstack/react-query';

import { useStore } from '../store';
import { useDeleteTimelog } from '../api/timelogs';

export function useTimelog() {
  const { date, deleteLocalTimelog } = useStore((state) => ({
    date: state.getFormattedDate(),
    deleteLocalTimelog: state.deleteTimelog,
  }));

  const queryClient = useQueryClient();
  const deleteTimelogQuery = useDeleteTimelog();

  const deleteTimelog = (id) => {
    deleteTimelogQuery.mutate(
      {
        input: {
          id,
        },
      },
      {
        onSuccess: ({ deleteTimelog }) => {
          if (deleteTimelog) {
            deleteLocalTimelog(id); // delete from local state aswell
          }

          queryClient.setQueryData(['remoteTimelogs', date], ({ me }) => {
            const previousTimelogs = me.timelogs;
            const updatedTimelogs = previousTimelogs.filter(
              (remoteTimelog) => remoteTimelog.id !== id,
            );

            return {
              me: {
                timelogs: updatedTimelogs,
              },
            };
          });
        },
      },
    );
  };

  return {
    deleteTimelog,
  };
}
