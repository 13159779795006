import { useStore } from '../store';
import Time from '../utils/time';

export const useBudgetLimit = () => {
  const { budget, ignoreBudgetLimit } = useStore((state) => ({
    budget: state.budget,
    ignoreBudgetLimit: state.ignoreBudgetLimit(),
  }));

  const isTaskOverBudget = budget?.current_left <= 0;

  const { is_hours_bundle, hours_bundle_used, hours_bundle } = budget ?? {};
  const isTaskOverBundleHours = is_hours_bundle && hours_bundle_used >= hours_bundle;

  const isBudgetCheckRequired = () => {
    // If budget limit is ignored, no need to check the budget
    if (ignoreBudgetLimit) return false;

    if (!budget) return false;
    if (!budget.max) return false; // no budget limit, no need to check
    if (budget.max === 0) return false; // budget limit is 0, no need to check

    // If none of the above conditions are met, a budget check is required
    return true;
  };

  const isOutOfBudget = () => {
    if (!isBudgetCheckRequired()) return false;

    // Check if part of hours bundle & and if it's over the limit
    if (is_hours_bundle) {
      return isTaskOverBundleHours;
    }

    return isTaskOverBudget;
  };

  const getFormattedBudgetLeft = () => {
    if (isTaskOverBudget) return '0:00';
    return Time.getFormattedTimeFromMinutes(budget?.current_left);
  };

  return {
    isBudgetCheckRequired,
    isTaskOverBudget,
    isTaskOverBundleHours,
    isOutOfBudget: isOutOfBudget(),
    ignoreBudgetLimit,
    getFormattedBudgetLeft,
  };
};
