import React from 'react';
import PropTypes from 'prop-types';
import { GraphQLClient } from 'graphql-request';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import * as Sentry from '@sentry/react';

import { ENV_PRODUCTION } from '../constants/environment';
import { useToast } from '../hooks/useToast';

export const endpoint = `${process.env.REACT_APP_API_URI}/graphql`;

const GraphQLClientContext = React.createContext(null);

const GraphQLClientProvider = ({ children }) => {
  const graphQLClient = new GraphQLClient(endpoint);
  const createToast = useToast();

  const onGraphQLError = (error, toast) => {
    Sentry.captureException(error);

    if (toast?.errorMessage) {
      createToast({ type: 'error', description: `${toast.errorMessage}` });
    }
  };

  const queryClient = new QueryClient({
    mutationCache: new MutationCache({
      onSuccess: (_error, _variables, _ctx, mutation) => {
        if (!mutation?.toast?.hide && mutation?.toast?.successMessage) {
          createToast({ description: `${mutation.toast.successMessage}` });
        }
      },
      onError: (error, _variables, _ctx, mutation) => {
        onGraphQLError(error, mutation.toast);
      },
    }),
    queryCache: new QueryCache({
      onError: (error, query) => {
        onGraphQLError(error, query.toast);
      },
    }),
  });

  const setAuthorizationHeader = (token) => {
    graphQLClient.setHeader('authorization', `Bearer ${token}`);
  };

  return (
    <GraphQLClientContext.Provider value={{ graphQLClient, setAuthorizationHeader }}>
      <QueryClientProvider client={queryClient}>
        {children}

        {process.env.REACT_APP_ENVIRONMENT !== ENV_PRODUCTION && <ReactQueryDevtools />}
      </QueryClientProvider>
    </GraphQLClientContext.Provider>
  );
};

export { GraphQLClientProvider, GraphQLClientContext };

GraphQLClientProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
