import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Center, Spinner } from '@chakra-ui/react';

import routes from '../../constants/routes';
import { useAuth } from '../../hooks/useAuth';
import { useGraphQLClient } from '../../hooks/useGraphQLClient';

export default function RequireAuth({ children }) {
  const auth = useAuth();
  const location = useLocation();
  const { setAuthorizationHeader } = useGraphQLClient();

  if (auth.loginInProgress) {
    return (
      <Center height="100vh">
        <Spinner color="blue.500" />
      </Center>
    );
  }

  if (!auth.token) {
    return <Navigate to={routes.login} state={{ from: location }} replace />;
  }

  useEffect(() => {
    if (auth.token) {
      setAuthorizationHeader(auth.token);
    }
  }, []);

  return children ?? <Outlet />;
}

RequireAuth.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
