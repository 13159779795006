import { extendTheme } from '@chakra-ui/react';

export const customChakraUi = extendTheme({
  colors: {
    planning: {
      50: '#edf6f6',
      100: '#dceeee',
    },
    blue: {
      100: '#C1C7DC',
      200: '#9AA3C4',
      300: '#7480AD',
      400: '#57669D',
      500: '#3A4C8D',
      600: '#344585',
      700: '#2c3c79',
      800: '#25326D',
      900: '#1A2256',
    },
  },
});
