import { useGqlQuery } from '../useGqlQuery';

import { GET_ME, GET_PLANNING, GET_RECENT_GITHUB_COMMITS } from './gqlUsers';

export const useMeQuery = () => {
  const { isLoading, isError, data } = useGqlQuery(['me'], GET_ME);
  const me = data?.me;
  return { isLoading, isError, me };
};

export const useRecentCommits = () => {
  const { isLoading, isError, data } = useGqlQuery(['recentCommits'], GET_RECENT_GITHUB_COMMITS);
  const recentCommits = data?.recentCommits ?? {};

  return { isLoading, isError, recentCommits };
};

export const usePlanningQuery = (date) => {
  const { isLoading, isError, data } = useGqlQuery(['planning', date], GET_PLANNING, { date });
  const planning = data?.me?.planning ?? [];

  return { isLoading, isError, planning };
};
