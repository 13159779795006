import { useRef, useEffect } from 'react';
import dayjs from 'dayjs';

export default function useDocumentTitle(title, prevailOnUnmount = false) {
  const defaultTitle = useRef(document.title);

  const getTimestamp = () => {
    const now = dayjs();
    return now.format('HH:mm');
  };

  useEffect(() => {
    const prefix = title ?? defaultTitle.current;
    document.title = `${prefix} ${getTimestamp()}`;
  }, [title]);

  useEffect(
    () => () => {
      if (!prevailOnUnmount) {
        document.title = defaultTitle.current;
      }
    },
    [],
  );
}
