import React from 'react';
import PropTypes from 'prop-types';

import { Text } from '@chakra-ui/react';
import Modal from './Modal';

import { useBudgetLimit } from '../../hooks/useBudgetLimit';

export default function BudgetLimit({ data, isOpen, onClose }) {
  const { isTaskOverBundleHours, getFormattedBudgetLeft } = useBudgetLimit();

  const renderContent = () => {
    if (isTaskOverBundleHours) {
      return (
        <Text>
          Er zijn <strong>geen uren meer beschikbaar op de urenbundel</strong>. Ga langs bij een
          Project Manager om jouw uren te bespreken.
        </Text>
      );
    }

    return (
      <>
        <p>
          Er is een budget van <strong>{data?.budget}</strong> voorzien voor de taak:{' '}
          <strong>{data?.task}</strong> van de klant: <strong>{data?.client}</strong>.
        </p>
        <br />
        <p>
          Waarvan er nog <strong>{getFormattedBudgetLeft()}</strong> beschikbaar zijn, ga langs een
          Project Manager om jouw uren (<strong>{data?.totalInputBudget}u</strong>) te bespreken.
        </p>
      </>
    );
  };

  return (
    <Modal title="Let op!" colorScheme="red" isOpen={isOpen} onClose={onClose} showCancel={false}>
      {renderContent()}
    </Modal>
  );
}

BudgetLimit.propTypes = {
  data: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
