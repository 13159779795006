import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { Box } from '@chakra-ui/react';

import { useStore } from '../../store';

import Pane from './Pane';
import TimeEntryInput from '../time-entry/TimeEntryInput';
import TimeTrackingForm from '../forms/TimeTrackingForm';

import SaveButton from '../save-button/SaveButton';

export default function TimeTrackingPane({ onFormSubmit, onTriggerModal }) {
  const { setTask, unsetTask, unsetBudget } = useStore((state) => ({
    setTask: state.setTask,
    unsetTask: state.unsetTask,
    budget: state.budget,
    unsetBudget: state.unsetBudget,
  }));

  const { resetField, watch } = useFormContext();

  // Reset task form field & in storage
  const resetTask = () => {
    resetField('task', { defaultValue: '' });
    unsetTask();
    unsetBudget();
  };

  // Form watcher on input changes
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      const { task, client } = value;

      // When client changes, unset & reset the task input
      if (name === 'client') {
        resetTask();
      }

      // When task changes and value is set
      if (name === 'task' && task.id) {
        // reset budget when changing tasks
        unsetBudget();

        // Set task with client in storage, used to view Task Detail
        setTask({ id: task.id, ...task, client });
      }

      // reset budget when changing to temporary task
      if (value.client === '') {
        unsetBudget();
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <Box flex={1}>
      <Pane
        header={<TimeEntryInput onTriggerModal={onTriggerModal} />}
        footer={<SaveButton onSubmit={onFormSubmit} />}
        childrenContainerProps={{ flex: 1, paddingX: { base: 6, lg: 8 }, paddingY: 4 }}
      >
        <TimeTrackingForm />
      </Pane>
    </Box>
  );
}

TimeTrackingPane.propTypes = {
  onFormSubmit: PropTypes.func,
  onTriggerModal: PropTypes.func,
};

TimeTrackingPane.defaultProps = {
  onFormSubmit: () => {},
  onTriggerModal: () => {},
};
