/* eslint-disable prefer-destructuring */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, HStack, Text } from '@chakra-ui/react';
import { FiClock } from 'react-icons/fi';

import { useBudgetStatus } from '../../hooks/useBudgetStatus';

import Time from '../../utils/time';

export default function HoursBundleBudget({ progress, budget }) {
  const { getBackgroundColor, getTextColor } = useBudgetStatus(progress, budget);

  if (budget <= 0) {
    return null;
  }

  return (
    <HStack
      backgroundColor={getBackgroundColor}
      color={getTextColor}
      borderRadius={5}
      paddingX={2}
      paddingY={1}
    >
      <FiClock />
      <Text>
        Urenbundel:{' '}
        <Box as="span" fontWeight="bold">
          {Time.getRemainingTimeInHours(budget, progress)} uur
        </Box>{' '}
        over
      </Text>
    </HStack>
  );
}

HoursBundleBudget.propTypes = {
  progress: PropTypes.number,
  budget: PropTypes.number,
};

HoursBundleBudget.defaultProps = {
  progress: 0,
  budget: 0,
};
