import React from 'react';
import PropTypes from 'prop-types';

import { useClients } from '../../api/clients';

import { useFormattedSelectOption } from '../../hooks/useFormattedSelectOption';

import ControlledSelect from './ControlledSelect';
import useUrlParams from '../../hooks/useUrlParams';

export default function ClientSearch({ control, isDisabled = false }) {
  const { getSearchParam } = useUrlParams();
  const clientIdFromParam = getSearchParam('client_id');

  const { createFormattedOption } = useFormattedSelectOption();
  const { clients, isLoading } = useClients(
    {
      first: 5000, // all clients
    },
    {
      transform: ({ clients }) => createFormattedOption(clients, { isCreatable: false }),
    },
  );

  const disabledDefaultValue = { name: '-- Selecteer klant --', id: null, isDisabled: true };
  const data = !isLoading ? [disabledDefaultValue, ...clients] : [disabledDefaultValue];

  const defaultValue = data.find(({ id }) => {
    if (!clientIdFromParam) {
      return undefined;
    }

    return id === clientIdFromParam;
  });

  return (
    <ControlledSelect
      control={control}
      id="client"
      name="client"
      placeholder="Zoek klanten"
      label="Klanten"
      data={data}
      isLoading={isLoading}
      defaultValue={defaultValue}
      isDisabled={isDisabled}
    />
  );
}

ClientSearch.propTypes = {
  control: PropTypes.object,
  isDisabled: PropTypes.bool,
};
