import React, { useEffect, useState } from 'react';
import { Box, Center, Text, HStack } from '@chakra-ui/react';
import { FiExternalLink } from 'react-icons/fi';

import { useStore } from '../../store';

import Pane from './Pane';
import TaskDetailForm from '../forms/TaskDetailForm';
import StatusButtons from '../status-buttons/StatusButtons';
import NotFound from '../not-found/NotFound';

import { useTask } from '../../api/tasks';

export default function TaskDetailPane() {
  const [combinedTimelog, setCombinedTimelog] = useState(null);
  const [useCombinedTimelog, setUseCombinedTimelog] = useState(false);
  const { storedTask, setTask, timelog } = useStore((state) => ({
    timelog: state.timelog,
    storedTask: state.task,
    setTask: state.setTask,
  }));

  // Get task id from stored task
  const id = storedTask?.id;

  // Fetch task data based on id
  const enabled = !!id;
  const { task, isLoading } = useTask(id, {
    enabled,
  });

  useEffect(() => {
    setUseCombinedTimelog(
      timelog?.task?.id && (timelog?.task?.id === task?.id || timelog?.task?.isNew),
    );
    if (timelog?.task?.id && (timelog?.task?.id === task?.id || timelog?.task?.isNew)) {
      setTask({ ...task, ...timelog?.task });
      setCombinedTimelog({ ...task, ...timelog?.task });
    } else if (task) {
      setTask(task);
    }
  }, [task]);

  return (
    <Box flex={1} marginY={8} marginX={3} marginLeft={0}>
      <Pane
        header={
          <Center padding={3} backgroundColor="blue.800" color="white" minH="65px">
            <HStack width="full" spacing={2}>
              <Box padding={3} flexGrow={1}></Box>
              <Box flexGrow={1}>
                <Text
                  casing="uppercase"
                  fontSize="sm"
                  style={{ marginRight: task?.id ? '-7rem' : 'auto' }}
                >
                  Taak
                </Text>
              </Box>
              <Box>
                {task?.id && (
                  <Box
                    borderRadius="md"
                    bg="blue.400"
                    color="white"
                    p={0}
                    _hover={{ color: 'blue.800', bgColor: 'white' }}
                  >
                    <a
                      href={`https://www.e-dev.be/tasks/show/${task?.id}`}
                      target="_blank"
                      rel="noreferrer"
                      style={{ display: 'flex', alignItems: 'center', padding: '0.5rem 1rem' }}
                    >
                      <span style={{ marginRight: '5px' }}>Edev</span>
                      <FiExternalLink size={13} />
                    </a>
                  </Box>
                )}
              </Box>
            </HStack>
          </Center>
        }
        footer={!isLoading ? <StatusButtons status={task?.status} /> : null}
        placeholder={<NotFound label="Er is nog geen taak gekozen" />}
        borderTopLeftRadius={0}
        childrenContainerProps={{ flex: 1, padding: 8, paddingBottom: 0 }}
      >
        {!isLoading && <TaskDetailForm task={useCombinedTimelog ? combinedTimelog : task} />}
      </Pane>
    </Box>
  );
}
