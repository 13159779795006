import { gql } from 'graphql-request';

import { FRAGMENT_TIMELOG } from '../timelogs/gqlTimelogs';

export const FRAGMENT_TASK = gql`
  fragment Task on Task {
    id
    parent_id
    name
    description
    status
    budget_time
    progress
    asana_url
    editable
    hours
    is_hours_bundle
    client {
      id
      name
      hours_bundle
      hours_bundle_used
    }
    project {
      id
      project_type_id
    }
  }
`;

export const GET_TASK = gql`
  query GetTaskById($id: ID!) {
    task(id: $id) {
      ...Task
      timelogs {
        ...Timelog
      }
    }
  }
  ${FRAGMENT_TASK}
  ${FRAGMENT_TIMELOG}
`;

export const CREATE_TASK = gql`
  mutation createTask($input: CreateTaskInput!) {
    createTask(input: $input) {
      ...Task
    }
  }
  ${FRAGMENT_TASK}
`;

export const UPDATE_TASK = gql`
  mutation updateTask($input: UpdateTaskInput!) {
    updateTask(input: $input) {
      ...Task
    }
  }
  ${FRAGMENT_TASK}
`;
