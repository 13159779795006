export const MonthNamesFull = [
  'Januari',
  'Februari',
  'Maart',
  'April',
  'Mei',
  'Juni',
  'Juli',
  'Augustus',
  'September',
  'Oktober',
  'November',
  'December',
];

export const MonthNamesShort = [
  'Jan',
  'Feb',
  'Mrt',
  'Apr',
  'Mei',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Okt',
  'Nov',
  'Dec',
];

export const WeekdayNamesShort = ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'];
export const WorkWeekdayNamesShort = ['Ma', 'Di', 'Wo', 'Do', 'Vr'];
