import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from '@chakra-ui/react';
import { useBudgetStatus } from '../../hooks/useBudgetStatus';

export default function BudgetBadge({ progress, budget }) {
  const { status } = useBudgetStatus(progress, budget);
  const { color } = status;

  return <Badge width="10px" height="10px" borderRadius="full" backgroundColor={color} mr={3} />;
}

BudgetBadge.propTypes = {
  progress: PropTypes.number,
  budget: PropTypes.number,
};
