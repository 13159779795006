import React from 'react';
import PropTypes from 'prop-types';
import { useErrorBoundary } from 'react-error-boundary';
import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button } from '@chakra-ui/react';

export default function ErrorFallback({ error }) {
  const { resetBoundary } = useErrorBoundary();

  return (
    <Alert status="error">
      <AlertIcon />
      <Box>
        <AlertTitle>Er ging iets verkeerd!</AlertTitle>
        <AlertDescription>{error?.message}</AlertDescription>
      </Box>
      <Button colorScheme="red" onClick={resetBoundary} ml="auto">
        Probeer opnieuw
      </Button>
    </Alert>
  );
}

ErrorFallback.propTypes = {
  error: PropTypes.string,
};
