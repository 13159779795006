import React, { useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import {
  FormControl,
  FormErrorMessage,
  NumberInput,
  NumberInputField,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import { useQueryClient } from '@tanstack/react-query';
import Modal from './Modal';

import { useStore } from '../../store';

import { useDistributeTimelogs } from '../../api/timelogs';
import { defaultFormValues } from '../../utils/form';

function DivideTime({ innerRef }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const queryClient = useQueryClient();

  const { date } = useStore((state) => ({
    date: state.getFormattedDate(),
  }));

  const distributeTimelogs = useDistributeTimelogs();

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isValid },
  } = useForm({
    shouldUseNativeValidation: false,
  });

  useImperativeHandle(innerRef, () => ({
    toggleOpen() {
      onOpen();
    },
  }));

  const onMutationSuccess = async () => {
    await queryClient.invalidateQueries(['remoteTimelogs', date]);
  };

  const divideTime = (minutes, onSuccess) => {
    const time = parseInt(minutes, 10);

    distributeTimelogs.mutate(
      {
        input: {
          time,
          date,
        },
      },
      {
        onSuccess,
      },
    );
  };

  const onDivideTimeSuccess = () => {
    onMutationSuccess();
    onClose();
  };

  const onSubmit = ({ time }) => {
    reset(defaultFormValues);

    if (isValid) {
      divideTime(time, onDivideTimeSuccess);
    }
  };

  return (
    <div ref={innerRef}>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        onCancel={reset}
        onConfirm={handleSubmit(onSubmit)}
        includeValidation={true}
        title="Tijd verdelen"
        confirmTitle="Tijd verdelen"
        isLoading={distributeTimelogs.isLoading}
      >
        <Text fontSize="sm" marginBottom={5}>
          Te gebruiken om op het einde van de dag tijd gespendeerd aan diverse emails / telefoons te
          verdelen over alle reeds geboekte taken, als het te moeilijk is om die tijd 1 op 1 toe te
          wijzen. (max 30min)
        </Text>
        <FormControl isInvalid={errors?.time}>
          <NumberInput defaultValue={30}>
            <NumberInputField
              {...register('time', {
                max: { value: 30, message: 'Je kan maximaal 30 minuten verdelen' },
                min: { value: 2, message: 'Je moet minimaal 2 minuten verdelen' },
              })}
            />
            {errors?.time && <FormErrorMessage>{errors.time.message}</FormErrorMessage>}
          </NumberInput>
        </FormControl>
      </Modal>
    </div>
  );
}

export default React.forwardRef((props, ref) => <DivideTime {...props} innerRef={ref} />);

DivideTime.propTypes = {
  innerRef: PropTypes.object,
};
