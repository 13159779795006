/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, ButtonGroup } from '@chakra-ui/react';
import { useFormContext, Controller } from 'react-hook-form';

// import { useIsMutating } from '@tanstack/react-query';
import RadioButton from '../radio-button/RadioButton';

const STATUS_OPTIONS = {
  IN_PROGRESS: 'OPEN',
  DONE: 'DONE',
};

export default function StatusButtons({ name, status }) {
  const { control, setValue } = useFormContext();
  const options = Object.values(STATUS_OPTIONS);

  const [isDone, setIsDone] = useState(status === 'DONE');
  const [currentStatus, setCurrentStatus] = useState(
    control._formValues.task.status ?? STATUS_OPTIONS.IN_PROGRESS,
  );
  const [defaultValue, setDefaultValue] = useState(
    isDone ? STATUS_OPTIONS.DONE : STATUS_OPTIONS.IN_PROGRESS,
  );

  useEffect(() => {
    setIsDone(status === 'DONE');
    setDefaultValue(status === 'DONE' ? STATUS_OPTIONS.DONE : STATUS_OPTIONS.IN_PROGRESS);
    setCurrentStatus(
      (control._formValues.task && control._formValues.task.status) ?? STATUS_OPTIONS.IN_PROGRESS,
    );
  }, [control._formValues.task, control._formValues.task.id, control._formValues.task.status]);

  return (
    <Box padding={8}>
      <ButtonGroup width="full" isAttached>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ field: { ref, value, onChange, ...field } }) =>
            options.map((status) => (
              <RadioButton
                {...field}
                ref={ref}
                key={status}
                isChecked={currentStatus === status}
                onChange={() => {
                  onChange(status);
                  //
                  setCurrentStatus(status);
                  setValue('task.status', status);
                }}
              >
                {STATUS_OPTIONS.IN_PROGRESS === status ? 'Nog bezig' : 'Afgewerkt'}
              </RadioButton>
            ))
          }
        />
      </ButtonGroup>
    </Box>
  );
}

StatusButtons.propTypes = {
  name: PropTypes.string,
  status: PropTypes.any,
};

StatusButtons.defaultProps = {
  name: 'task.status',
};
