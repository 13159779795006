import { useEffect, useState } from 'react';
import { theme } from '@chakra-ui/react';

export const BUDGET_STATUS_GOOD = 'good';
export const BUDGET_STATUS_WARNING = 'warning';
export const BUDGET_STATUS_BAD = 'bad';
export const BUDGET_STATUS_DEFAULT = 'default';

const STATUS_GOOD = { type: BUDGET_STATUS_GOOD, color: 'green.300' };
const STATUS_WARNING = { type: BUDGET_STATUS_WARNING, color: 'orange.300' };
const STATUS_BAD = { type: BUDGET_STATUS_BAD, color: 'red.500' };
const STATUS_DEFAULT = { type: BUDGET_STATUS_DEFAULT, color: 'blue.800' };

export const useBudgetStatus = (progress = 0, budget = 0) => {
  const [status, setStatus] = useState(STATUS_DEFAULT);
  const { type, color } = status;

  // In minutes
  const threshold = 60;

  // When there's no budget, set task to BAD when progress reaches 4 hours
  const maxTimeWithoutBudget = 240;

  const getBackgroundColor = () => {
    let backgroundColor = theme.colors.transparent;
    if (type === BUDGET_STATUS_WARNING || type === BUDGET_STATUS_BAD) {
      backgroundColor = color;
    }
    return backgroundColor;
  };

  const getTextColor = () => {
    let textColor = theme.colors.black;
    if (type === BUDGET_STATUS_WARNING || type === BUDGET_STATUS_BAD) {
      textColor = theme.colors.white;
    }
    return textColor;
  };

  useEffect(() => {
    if (budget === 0) {
      setStatus(progress > maxTimeWithoutBudget ? STATUS_BAD : STATUS_GOOD);
    } else if (progress >= budget) {
      setStatus(STATUS_BAD);
    } else if (budget - progress <= threshold) {
      setStatus(STATUS_WARNING);
    } else if (progress < budget) {
      setStatus(STATUS_GOOD);
    }
  }, [progress, budget]);

  return {
    status,
    getBackgroundColor,
    getTextColor,
  };
};
