import { useCallback } from 'react';
import { createUid } from '../utils/helper';

import { PROJECT_TYPE_ID_CLOSED } from '../constants/enums';

// Data when creating 'Nieuwe taak' option in list
const createCreatableOption = (data) => ({
  id: createUid(),
  parent_id: null,
  name: 'Nieuwe taak',
  isNew: true,
  editable: true,
  ...data,
});

// Data when formatting task options in list
const createTaskOption = ({ id, parent_id, name, progress, budget_time }, data) => ({
  id,
  parent_id,
  name,
  progress,
  budget_time,
  ...data,
});

export const useFormattedSelectOption = () => {
  // Dynamically create react-select transformed object from data
  // Inserts options array to create optgroup when tasks are set
  // Inserts Create task button at the top of each optgroup
  const formattedOption = (data, { isCreatable = true }) => {
    const formattedData = data.map(({ id, name, tasks, project_type_id }) => {
      // Single ungrouped task, return id & name
      if (!tasks) {
        return {
          id,
          name,
        };
      }

      const project = {
        id,
        project_type_id,
      };

      // Grouped tasks (projects with tasks)
      const options = tasks.map((task) => createTaskOption(task, { project }));

      // Add creatable task option to top of list if project is not closed
      if (project_type_id !== PROJECT_TYPE_ID_CLOSED) {
        if (isCreatable) {
          options.unshift(createCreatableOption({ project: { id } }));
        }
      }

      // Have to use default value & label keys for react-select
      // as this is used for the group heading (project name)
      return {
        value: id,
        label: name,
        options,
      };
    });

    // Add creatable task option to top of list
    if (isCreatable) {
      formattedData.unshift(createCreatableOption());
    }

    return formattedData;
  };

  const createFormattedOption = useCallback(formattedOption, []);

  const createCreatableFormattedOption = useCallback(
    (data, options) => formattedOption(data, options),
    [],
  );

  return { createFormattedOption, createCreatableFormattedOption };
};
