import React from 'react';
import PropTypes from 'prop-types';
import MultiCopySelect from '../select/MutliCopySelect';

import { useGithubCommits } from '../../api/commits';

export default function CommitList({ name }) {
  const { commits, refetch, isFetching } = useGithubCommits();

  return (
    <MultiCopySelect
      name={name}
      label="Git commits"
      emptyLabel="Geen commits gevonden.."
      destination="timelog.comments"
      data={commits}
      refetch={refetch}
      isLoading={isFetching}
    />
  );
}

CommitList.propTypes = {
  name: PropTypes.string,
};
