import React, { forwardRef } from 'react';
import { Button } from '@chakra-ui/react';

// eslint-disable-next-line react/prop-types
function RadioButton({ children, isChecked, onChange, ...props }, ref) {
  return (
    <Button
      as="label"
      flex={1}
      variant="outline"
      cursor="pointer"
      borderColor={isChecked ? 'blue.600' : 'gray.200'}
      bg={isChecked ? 'blue.600' : 'white'}
      color={isChecked ? 'white' : 'gray.800'}
      _hover={{
        borderColor: isChecked ? 'blue.600' : 'gray.300',
        bg: isChecked ? 'blue.600' : 'gray.200',
      }}
    >
      <input ref={ref} {...props} type="radio" checked={isChecked} onChange={onChange} hidden />
      {children}
    </Button>
  );
}

export default forwardRef(RadioButton);
