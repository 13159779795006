import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, IconButton, Text, useDisclosure, Tag } from '@chakra-ui/react';
import { FiClock, FiTrash } from 'react-icons/fi';

import { useStore } from '../../store';
import { usePlanning } from '../../hooks/usePlanning';
import { useTimelog } from '../../hooks/useTimelog';

import BudgetBadge from '../budget/BudgetBadge';
import TimeEntryName from './TimeEntryName';
import DeleteTimeModal from '../modal/DeleteTimeModal';

import Time from '../../utils/time';

export default function TimeEntryListItem({ active, timelog, isPlanning }) {
  const [isHovered, setIsHovered] = useState(false);
  const { setTimelog, deleteLocalTimelog } = useStore((state) => ({
    setTimelog: state.setTimelog,
    deleteLocalTimelog: state.deleteTimelog,
  }));

  const { getPlanningHoursByTask } = usePlanning();
  const { deleteTimelog } = useTimelog();
  const { onOpen, isOpen, onClose } = useDisclosure();

  const { id, hours, minutes, task, isRemote } = timelog;
  const { name, client, progress, budget_time, editable = true, project } = task ?? {};

  const isLocal = !isRemote && (name || client);
  // Local timelogs don't have a task, so you should be able to click on them
  const isTimelogClickable = !task || editable;
  const planningHours = isPlanning ? getPlanningHoursByTask(task.id) : 0;

  const onHover = (state) => {
    if (!editable) return;
    setIsHovered(state);
  };

  const handleOnClick = () => {
    if (!isTimelogClickable) return;
    setTimelog(timelog);
  };

  const handleDelete = () => {
    if (!isRemote) {
      deleteLocalTimelog(id);
      return;
    }

    deleteTimelog(id);
  };

  const handleMouseEnter = () => {
    onHover(true);
  };

  const handleMouseLeave = () => {
    onHover(false);
  };

  const getBackgroundColor = () => {
    if (isPlanning) {
      return active ? 'planning.100' : 'planning.50';
    }

    if (isLocal) {
      return active ? 'orange.100' : 'orange.50';
    }

    if (active) {
      return 'gray.100';
    }

    return '';
  };

  const getHoverBackgroundColor = () => {
    if (isPlanning && !active) {
      return 'planning.100';
    }

    if (isLocal) {
      return 'orange.100';
    }

    if (!active) {
      return 'gray.50';
    }

    return '';
  };

  return (
    <>
      <Flex
        key={id}
        position="relative"
        alignItems="center"
        justifyContent="space-between"
        paddingBlock={5}
        minHeight={30}
        paddingInline={5}
        width="full"
        cursor={isTimelogClickable ? 'pointer' : 'not-allowed'}
        backgroundColor={getBackgroundColor()}
        bgGradient={
          !isTimelogClickable &&
          'repeating-linear-gradient(45deg, white, white 10px, gray.100 10px, gray.100 20px )'
        }
        onClick={handleOnClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        _hover={{
          backgroundColor: getHoverBackgroundColor(),
        }}
      >
        <Box width="75px">
          {`${Time.getTwoDigitNumberFromValue(hours)}:${Time.getTwoDigitNumberFromValue(minutes)}`}
          <Text fontSize="xs">
            {isPlanning && ` / ${Time.getTwoDigitNumberFromValue(planningHours)}:00`}
          </Text>
        </Box>
        <BudgetBadge progress={progress} budget={budget_time} />
        <Box flex={1} ml={2} pr={14}>
          {isLocal && (
            <Tag size="sm" variant="solid" bgColor="orange.300">
              Tijdelijke timelog
            </Tag>
          )}
          {isPlanning && (
            <Tag size="sm" variant="solid" bgColor="#3B4B8D">
              Planning
            </Tag>
          )}
          <TimeEntryName name={name} client={client} project={project} />
        </Box>
        <Flex position="absolute" right="3" alignItems="center" gap="3">
          {active ? <FiClock /> : <Box width={4} />}
          {(active || isHovered) && !isPlanning && (
            <IconButton variant="outline" size="xs" icon={<FiTrash />} onClick={onOpen} />
          )}
        </Flex>
      </Flex>

      <DeleteTimeModal
        onConfirm={handleDelete}
        isLoading={deleteTimelog.isLoading}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
}

TimeEntryListItem.propTypes = {
  timelog: PropTypes.object.isRequired,
  active: PropTypes.bool,
  isPlanning: PropTypes.bool,
};

TimeEntryListItem.defaultProps = {
  timelog: {},
};
