import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Flex, FormControl, FormLabel, IconButton, Select } from '@chakra-ui/react';
import { FiArrowUpCircle, FiRefreshCw } from 'react-icons/fi';
import { useFormContext } from 'react-hook-form';
import Date from '../../utils/date';

// 13 = Enter
// 32 = Spacebar
const ALLOWED_KEYCODES = [13, 32];

export default function MultiCopySelect({
  data,
  name,
  label,
  emptyLabel,
  destination,
  refetch,
  isLoading,
}) {
  const [selectedValue, setSelectedValue] = useState('');
  const { getValues, setValue, register } = useFormContext();

  const onCopy = () => {
    const currentComments = getValues(destination);
    const insertNewLine = currentComments ? '\n' : '';
    const newComments = `${currentComments}${insertNewLine}${selectedValue}`;
    setValue(destination, newComments);
  };

  // Get selected item and append to destination field
  const handleOnChange = (ev) => {
    const item = ev.target.value;
    setSelectedValue(item);
  };

  const handleKeyDown = (ev) => {
    if (!ALLOWED_KEYCODES.includes(ev.keyCode)) return;
    onCopy();
  };

  return (
    <FormControl>
      <Flex justifyContent="space-between" alignItems="center" mb={2}>
        {!!label && (
          <FormLabel htmlFor="commits" size="sm" mb={0}>
            {label}
            <IconButton
              size="sm"
              variant="ghost"
              ml={2}
              icon={<FiArrowUpCircle />}
              onClick={onCopy}
            />
          </FormLabel>
        )}
        {!!refetch && (
          <IconButton
            size="sm"
            variant="ghost"
            icon={<FiRefreshCw size={15} />}
            onClick={refetch}
            isLoading={isLoading}
          />
        )}
      </Flex>
      <Select
        {...register(name)}
        style={{ padding: 0 }}
        height="20vh"
        icon={<></>}
        multiple
        onChange={handleOnChange}
        onKeyDown={handleKeyDown}
      >
        {data.length > 0 ? (
          data.map(({ id, date, message, user, comments }, index) => {
            if (!message && !comments) return null;

            return (
              <option key={index} defaultValue={id} style={{ paddingInline: 15, paddingBlock: 8 }}>
                {date
                  ? `${Date.getShortMonthAndDay(date)} (${user?.first_name}): ${comments}`
                  : message}
              </option>
            );
          })
        ) : (
          <option style={{ paddingInline: 15, paddingBlock: 8 }} disabled>
            {emptyLabel}
          </option>
        )}
      </Select>
    </FormControl>
  );
}

MultiCopySelect.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  destination: PropTypes.string.isRequired,
  label: PropTypes.string,
  emptyLabel: PropTypes.string,
  refetch: PropTypes.func,
  isLoading: PropTypes.bool,
};

MultiCopySelect.defaultProps = {
  emptyLabel: 'Geen items gevonden..',
  data: [],
};
