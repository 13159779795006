/* eslint-disable react/prop-types */
import { React, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select, chakraComponents } from 'chakra-react-select';
import { useController, useFormContext } from 'react-hook-form';
import { FiPlusCircle, FiArrowRight } from 'react-icons/fi';
import { Flex, FormControl, FormLabel, Icon } from '@chakra-ui/react';

const components = {
  GroupHeading: ({ children, ...props }) => (
    <chakraComponents.GroupHeading {...props}>
      <Flex fontSize={16} fontWeight={700}>
        {children}
      </Flex>
    </chakraComponents.GroupHeading>
  ),
  Option: ({ children, ...props }) => {
    // Data from the option
    const { isNew, parent_id } = props.data;

    return (
      <chakraComponents.Option {...props}>
        <Flex align="center" ml={2} fontSize={14}>
          {isNew && <Icon as={FiPlusCircle} mr={2} />}
          {parent_id && <Icon as={FiArrowRight} mx={2} />}
          {children}
        </Flex>
      </chakraComponents.Option>
    );
  },
};

export default function ControlledSelect({
  id,
  name,
  label,
  rules,
  placeholder,
  data,
  control,
  isMulti,
  closeMenuOnSelect,
  defaultValue,
  ...props
}) {
  const { setValue } = useFormContext();
  const { field } = useController({
    name,
    control,
    rules,
  });
  const { ref, value, onChange, onBlur } = field;

  // Programmatically set async defaultValue
  useEffect(() => {
    if (!defaultValue) return;
    setValue(name, defaultValue);
  }, [defaultValue]);

  return (
    <FormControl id={id}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <Select
        ref={ref}
        name={name}
        value={value}
        options={data}
        onChange={onChange}
        onBlur={onBlur}
        isMulti={isMulti}
        closeMenuOnSelect={closeMenuOnSelect}
        placeholder={placeholder}
        components={components}
        loadingMessage={() => 'Laden...'}
        noOptionsMessage={() => 'Geen opties gevonden'}
        getOptionLabel={({ name }) => name}
        getOptionValue={({ id }) => id}
        {...props}
      />
    </FormControl>
  );
}

ControlledSelect.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  rules: PropTypes.object,
  placeholder: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  isMulti: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
};

ControlledSelect.defaultProps = {
  isMulti: false,
  closeMenuOnSelect: true,
};
