import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import dayjs from 'dayjs';
import { setDefaultOptions } from 'date-fns';
import { nlBE } from 'date-fns/locale';

import { AuthProvider } from './providers/AuthProvider';

import Timer from './views/Timer';
import Login from './views/Login';

import RequireAuth from './components/base/RequireAuth';

import useDocumentTitle from './hooks/useDocumentTitle';
import useUrlParams from './hooks/useUrlParams';
import useConfirmBrowserClose from './hooks/useConfirmBrowserClose';
import { useStore } from './store';

import routes from './constants/routes';

function App() {
  // eslint-disable-next-line no-unused-vars
  const [_, setShowPrompt] = useConfirmBrowserClose();

  const { hasSearchParam } = useUrlParams();
  const { activeTimelog, storedDate, setDate, task } = useStore((state) => ({
    storedDate: state.date,
    setDate: state.setDate,
    task: state.task,
    activeTimelog: state.timelog,
  }));

  // Set page title with task name
  useDocumentTitle(task?.name);

  // Set date-fns default locale
  setDefaultOptions({ locale: nlBE });

  useEffect(() => {
    // If ?date= param is set return early as we don't want to set the current day in state
    if (hasSearchParam('date')) return;

    const today = dayjs();
    if (dayjs(storedDate).isBefore(today) || dayjs(storedDate).isAfter(today)) {
      setDate(today);
    }
  }, []);

  useEffect(() => {
    setShowPrompt(!!activeTimelog);
  }, [activeTimelog]);

  return (
    <AuthProvider>
      <Routes>
        <Route element={<RequireAuth />}>
          <Route index element={<Timer />} />
        </Route>
        <Route path={routes.login} element={<Login />} />
        <Route path="*" element={<p>Page not found</p>} />
      </Routes>
    </AuthProvider>
  );
}

export default App;
