import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Center, Spinner, Button } from '@chakra-ui/react';

import { useAuth } from '../../hooks/useAuth';

export default function Login() {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  const handleLogin = async () => auth.login();

  useEffect(() => {
    if (!auth.token) return;

    // redirect when authenticated
    navigate(from, { replace: true });
  }, [auth.token]);

  if (auth.loginInProgress) {
    return (
      <Center height="100vh">
        <Spinner color="blue.500" />
      </Center>
    );
  }

  if (!auth.token) {
    return (
      <Center height="100vh" backgroundColor="gray.300">
        <Button onClick={handleLogin}>Login Etimer</Button>
      </Center>
    );
  }

  return (
    <Center height="100vh">
      <p>Successfully logged in</p>
    </Center>
  );
}
