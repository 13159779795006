import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

export default function Modal(props) {
  const {
    title,
    size,
    children,
    isOpen,
    onClose,
    onConfirm,
    confirmTitle,
    onCancel,
    includeValidation,
    colorScheme,
    isLoading,
    showCancel,
  } = props;

  const initialRef = React.useRef(null);

  const handleConfirm = () => {
    onConfirm();

    if (isLoading || !includeValidation) {
      onClose();
    }
  };

  const handleCancel = () => {
    onClose();
    onCancel();
  };

  return (
    <ChakraModal
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={initialRef}
      size={size}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        {children && <ModalBody>{children}</ModalBody>}

        <ModalFooter>
          {showCancel && (
            <Button variant="ghost" mr={3} onClick={handleCancel}>
              Annuleer
            </Button>
          )}
          <Button
            ref={initialRef}
            colorScheme={colorScheme}
            onClick={handleConfirm}
            isLoading={isLoading}
          >
            {confirmTitle}
          </Button>
        </ModalFooter>
      </ModalContent>
    </ChakraModal>
  );
}

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  confirmTitle: PropTypes.string,
  includeValidation: PropTypes.bool,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', '2xl', 'full']),
  colorScheme: PropTypes.string,
  isLoading: PropTypes.bool,
  showCancel: PropTypes.bool,
};

Modal.defaultProps = {
  size: 'md',
  children: null,
  isOpen: false,
  confirmTitle: 'OK',
  includeValidation: false,
  colorScheme: 'blue',
  isLoading: false,
  showCancel: true,
  onClose: () => {},
  onCancel: () => {},
  onConfirm: () => {},
};
