import { useEffect, useReducer } from 'react';
import dayjs from 'dayjs';

import useInterval from './useInterval';

import Time from '../utils/time';

const actions = {
  START: 'START',
  PAUSE: 'PAUSE',
  RESET: 'RESET',
  TICK: 'TICK',
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.START: {
      const newPrevTime = dayjs().toDate();
      return {
        ...state,
        isRunning: true,
        prevTime: newPrevTime,
        seconds: state.passedSeconds + Time.getSecondsFromPrevTime(newPrevTime),
      };
    }
    case actions.PAUSE: {
      return {
        ...state,
        passedSeconds: state.seconds,
        isRunning: false,
      };
    }
    case actions.RESET: {
      const newPassedSeconds = Time.getSecondsFromExpiry(action.payload.offset) || 0;
      const newPrevTime = dayjs().toDate();
      return {
        ...state,
        isRunning: action.payload.newAutoStart,
        prevTime: newPrevTime,
        passedSeconds: newPassedSeconds,
        seconds: newPassedSeconds + Time.getSecondsFromPrevTime(newPrevTime),
      };
    }
    case actions.TICK: {
      return {
        ...state,
        seconds: state.passedSeconds + Time.getSecondsFromPrevTime(state.prevTime),
      };
    }
    default:
      return state;
  }
};

export default function useStopwatch({ autoStart, offsetTimestamp }) {
  const initialState = {
    isRunning: autoStart,
    passedSeconds: Time.getSecondsFromExpiry(offsetTimestamp) || 0,
    prevTime: dayjs(),
    seconds: 0,
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const { seconds, isRunning } = state;

  useInterval(() => dispatch({ type: actions.TICK }), isRunning ? 1000 : null);

  const start = () => {
    dispatch({ type: actions.START });
  };

  const pause = () => {
    dispatch({ type: actions.PAUSE });
  };

  const reset = (offset = 0, newAutoStart = true) => {
    dispatch({ type: 'RESET', payload: { offset, newAutoStart } });
  };

  useEffect(() => {
    if (offsetTimestamp > 0) {
      dispatch({
        type: actions.RESET,
        payload: {
          offset: offsetTimestamp,
          newAutoStart: true,
        },
      });
    }
  }, [offsetTimestamp]);

  return [
    { ...Time.getTimeFromSeconds(seconds), isRunning },
    { start, pause, reset },
  ];
}
