import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Text, Input, keyframes } from '@chakra-ui/react';

import { useFormContext } from 'react-hook-form';
import Time from '../../utils/time';

const pulse = keyframes`
  from { opacity: 1 }
  50% {opacity: 0.8 }
  to { opacity: 1 }
`;

const animation = `${pulse} infinite 1s ease-in-out`;

const disallowedKeys = ['E', 'e', '+', '-', ','];

export default function Digit({
  value,
  title,
  name,
  onChange,
  onBlur,
  isRunning,
  disabled,
  small,
  ...props
}) {
  const { register, setValue } = useFormContext();

  const formatValue = (currentValue) => Time.getTwoDigitNumberFromValue(currentValue);

  const handleBlur = (ev) => {
    let { value } = ev.target;

    if (value.length > 3) {
      value = value.slice(0, 3);
    }

    onBlur(parseInt(ev.target.value, 10));
  };

  const handleChange = (ev) => {
    let { value } = ev.target;

    if (value.length > 3) {
      value = value.slice(0, 3);
    }

    setValue(`timelog.timer.${name}`, parseInt(ev.target.value, 10));
    onChange(parseInt(ev.target.value, 10));
  };

  const preventDisallowedKeys = (ev) => disallowedKeys.includes(ev.key) && ev.preventDefault();

  useEffect(() => {
    setValue(`timelog.timer.${name}`, formatValue(value));
  }, [name, value, setValue]);

  return (
    <Box flex={1}>
      {title && (
        <Text align="center" fontSize="xs">
          {title}
        </Text>
      )}
      <Box borderWidth={small ? '0px' : '1px'} borderRadius="lg" padding={small ? 0 : 2}>
        <Input
          {...register(`timelog.timer.${name}`, { value: formatValue(value) })}
          fontSize={small ? '0.6vw' : '3.25vw'}
          lineHeight="1"
          variant="unstyled"
          animation={isRunning ? animation : ''}
          style={{
            fontVariantNumeric: 'tabular-nums',
            textAlign: 'center',
            maxWidth: small ? '80px' : '100%',
          }}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={preventDisallowedKeys}
          type="number"
          disabled={disabled}
          {...props}
        />
      </Box>
    </Box>
  );
}

Digit.propTypes = {
  value: PropTypes.number.isRequired,
  title: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  isRunning: PropTypes.bool,
  disabled: PropTypes.bool,
  small: PropTypes.bool,
};

Digit.defaultProps = {
  onChange: () => {},
  onBlur: () => {},
  small: false,
};
