import React from 'react';
import { Box } from '@chakra-ui/react';

import Pane from './Pane';
import DailyReportStatusBar from '../report/DailyReportStatusBar';
import WeeklyReportStatusBar from '../report/WeeklyReportStatusBar';
import TimeEntryList from '../time-entry/TimeEntryList';

import useCombinedTimelogs from '../../hooks/useCombinedTimelogs';

export default function DailyReportPane() {
  const { timelogs, isFetching } = useCombinedTimelogs();

  return (
    <Box flex={1} marginY={8} marginX={3} marginRight={0}>
      <Pane
        isLoading={isFetching}
        header={<DailyReportStatusBar isFetching={isFetching} />}
        footer={<WeeklyReportStatusBar />}
        borderTopRightRadius={0}
      >
        <Box overflowY="auto">
          <TimeEntryList timelogs={timelogs} hasTimelogs={!!timelogs.length} />
        </Box>
      </Pane>
    </Box>
  );
}
