import React from 'react';
import PropTypes from 'prop-types';
import { AuthContext, AuthProvider as OAuthProvider } from 'react-oauth2-code-pkce';

const discovery = {
  authorizationEndpoint: `${process.env.REACT_APP_API_URI}/oauth/authorize`,
  tokenEndpoint: `${process.env.REACT_APP_API_URI}/oauth/token`,
  logoutEndpoint: `${process.env.REACT_APP_API_URI}/oauth/logout`,
};

const scopes = [
  'read_all_clients',
  'read_all_portfolios',
  'read_all_projects',
  'read_all_users',
  'read_basic_profile',
  'read_full_profile',
  'read_all_tasks',
  'write_all_tasks',
  'read_all_timelogs',
  'read_timelogs',
  'write_all_timelogs',
];

const AuthProvider = ({ children }) => {
  const config = {
    clientId: process.env.REACT_APP_API_AUTH_CLIENT_ID,
    redirectUri: `${window.location.origin}/login`,
    scope: scopes.join(' '),
    ...discovery,
  };

  return <OAuthProvider authConfig={config}>{children}</OAuthProvider>;
};

export { AuthProvider, AuthContext };

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
