import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@chakra-ui/react';

export default function TimeEntryName({ name = 'Tijdelijke taak', client, project }) {
  return (
    <>
      {client && <Text fontWeight="bold">{client?.name}</Text>}
      {project && (
        <Text fontSize={14} fontWeight="bold">
          {project?.name}
        </Text>
      )}
      <Text fontSize={14} mt={1}>
        {name}
      </Text>
    </>
  );
}

TimeEntryName.propTypes = {
  name: PropTypes.string,
  client: PropTypes.shape({
    name: PropTypes.string,
  }),
  project: PropTypes.shape({
    name: PropTypes.string,
  }),
};
