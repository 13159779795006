import create from 'zustand';
import { subscribeWithSelector, persist, devtools } from 'zustand/middleware';

import createActiveDateSlice from './createActiveDateSlice';
import createTimelogsSlice from './createTimelogsSlice';
import createUnspecifiedTimelogsSlice from './createUnspecifiedTimelogsSlice';
import createTaskSlice from './createTaskSlice';
import createBudgetSlice from './createBudgetSlice';

export const useStore = create(
  devtools(
    persist(
      subscribeWithSelector((set, get) => ({
        ...createUnspecifiedTimelogsSlice(set, get),
        ...createActiveDateSlice(set, get),
        ...createTimelogsSlice(set, get),
        ...createTaskSlice(set, get),
        ...createBudgetSlice(set, get),
      })),
      {
        name: 'time-log-storage',
        partialize: (state) =>
          Object.fromEntries(
            Object.entries(state).filter(([key]) => !['task', 'timelog'].includes(key)),
          ),
      },
    ),
  ),
);
