import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Center, VStack } from '@chakra-ui/react';
import { FiPlus } from 'react-icons/fi';
import { useFormContext, useWatch } from 'react-hook-form';

import TimeEntryListItem from './TimeEntryListItem';
import DivideTimeModal from '../modal/DivideTimeModal';

import { useStore } from '../../store';
import { defaultFormValues } from '../../utils/form';
import { useUpdateTimelog } from '../../api/timelogs';

export default function TimeEntryList({ timelogs, hasTimelogs }) {
  const { activeTimelog, unsetTimelog, unsetTask, setTask, prependTimelog, updateLocalTimelog } =
    useStore((state) => ({
      activeTimelog: state.timelog,
      unsetTimelog: state.unsetTimelog,
      unsetTask: state.unsetTask,
      setTask: state.setTask,
      prependTimelog: state.prependTimelog,
      updateLocalTimelog: state.updateTimelog,
    }));

  const divideTimeModal = useRef();
  const updateTimelog = useUpdateTimelog({ hideToast: true });
  const { reset, getValues, control } = useFormContext();

  // Watch values
  const subscribeTask = useWatch({
    control,
    name: 'task',
  });

  const subscribeClient = useWatch({
    control,
    name: 'client',
  });

  const subscribeComments = useWatch({
    control,
    name: 'timelog.comments',
  });

  const onCreateTimelog = () => {
    // reset form values on new timelog
    reset(defaultFormValues);
    prependTimelog({ includeTask: false });
  };

  const onUpdateTimelog = (
    store = false,
    storeTask = null,
    storeClient = null,
    storeComments = null,
  ) => {
    const { timelog, task, client } = getValues();

    const updatedTimelog = {
      task: {
        id: task?.id,
      },
      timelog: {
        ...timelog,
      },
    };

    if (store) {
      updateLocalTimelog({
        data: {
          task: storeTask,
          comments: storeComments || timelog?.comments,
          client: storeClient || client,
          isRemote: false,
        },
      });
    } else {
      updateTimelog(updatedTimelog);
    }
  };

  useEffect(() => {
    unsetTimelog();
  }, []);

  //  When selecting task in left panel
  useEffect(() => {
    if (!activeTimelog || !activeTimelog.task) {
      unsetTask();

      if (!activeTimelog) {
        unsetTimelog();
      }

      reset(defaultFormValues);
      return;
    }

    if (activeTimelog?.task) {
      const { task } = activeTimelog;
      const { client } = task;

      setTask({
        id: task?.id,
        ...task,
        client,
      });

      // Following form structure
      const currentTimelog = {
        ...activeTimelog,
        client,
        task,
      };

      reset({ timelog: currentTimelog });
    } else {
      unsetTask();
    }
  }, [activeTimelog]);

  // Save timelog when selecting new active timelog
  useEffect(
    () => () => {
      if (!activeTimelog || !activeTimelog?.isRemote) return null;
      return onUpdateTimelog();
    },
    [activeTimelog],
  );

  useEffect(() => {
    if (!activeTimelog || !getValues('client') || activeTimelog?.isRemote) return;
    onUpdateTimelog(true, subscribeTask, subscribeClient, subscribeComments);
  }, [subscribeTask, subscribeClient, subscribeComments]);

  return (
    <>
      <Center
        padding={4}
        position="sticky"
        top="0"
        background="white"
        zIndex="docked"
        flexWrap="wrap"
        gap={3}
      >
        <Button onClick={onCreateTimelog} colorScheme="blue" leftIcon={<FiPlus />}>
          Nieuw
        </Button>
        <Button
          variant="ghost"
          color={hasTimelogs ? 'blue.500' : 'gray.400'}
          onClick={() => (hasTimelogs ? divideTimeModal.current.toggleOpen() : {})}
          disabled={!hasTimelogs}
        >
          Extra verdelen %
        </Button>
      </Center>
      <VStack spacing={0}>
        {timelogs.map((timelog) => (
          <TimeEntryListItem
            key={timelog.id}
            timelog={timelog}
            active={activeTimelog?.id === timelog.id}
            isPlanning={timelog.is_planning}
          />
        ))}
      </VStack>

      <DivideTimeModal ref={divideTimeModal} />
    </>
  );
}

TimeEntryList.propTypes = {
  timelogs: PropTypes.arrayOf(PropTypes.object),
  hasTimelogs: PropTypes.bool,
};
