import { useEffect, useMemo } from 'react';

import { useRemoteTimelogsQuery } from '../api/timelogs';
import { useStore } from '../store';

export default function useCombinedTimelogs() {
  const { date, localTimelogs, updateRegisteredTime } = useStore((state) => ({
    date: state.getFormattedDate(),
    localTimelogs: state.timelogs,
    updateRegisteredTime: state.updateRegisteredTime,
  }));

  const { timelogs: remoteTimelogs, ...query } = useRemoteTimelogsQuery(
    { date },
    {
      enabled: !!date,
    },
  );

  const sortTimelogs = (a, b) => {
    // Assign weights based on the type of timelog
    const getWeight = (timelog) => {
      if (timelog.is_planning) return 1;
      if (timelog.isRemote) return 3;
      return 2; // Local timelogs
    };

    const weightA = getWeight(a);
    const weightB = getWeight(b);

    // Compare the weights to determine the order
    if (weightA < weightB) {
      return -1;
    }

    if (weightA > weightB) {
      return 1;
    }

    return 0;
  };

  const combinedTimelogs = remoteTimelogs
    ? [...remoteTimelogs, ...localTimelogs]
    : [...localTimelogs];

  const sortedCombinedTimelogs = combinedTimelogs.sort(sortTimelogs);

  const registeredTime = useMemo(
    () =>
      combinedTimelogs.reduce(
        (previousValue, currentValue) => previousValue + currentValue.time,
        0,
      ),
    [localTimelogs, remoteTimelogs],
  );

  useEffect(() => {
    updateRegisteredTime(registeredTime);
  }, [registeredTime, updateRegisteredTime]);

  return {
    timelogs: sortedCombinedTimelogs,
    remoteTimelogs,
    localTimelogs,
    ...query,
  };
}
