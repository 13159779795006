import React from 'react';
import PropTypes from 'prop-types';
import { Center, Wrap, WrapItem, Text } from '@chakra-ui/react';
import Digit from './Digit';

export default function BudgetLimitTimer({
  timerColor,
  timerStopTimestamp,
  hours,
  minutes,
  seconds,
  isRunning,
}) {
  return (
    <Center backgroundColor={timerColor} color="white" paddingX={8} zIndex={1}>
      <div style={{ marginTop: '-15px', marginBottom: '15px', fontSize: '14px' }}>
        <Wrap align="center">
          <WrapItem>
            <Text fontSize="0.6vw">Timer gestopt op {timerStopTimestamp}, sindsdien is er al</Text>
          </WrapItem>

          <WrapItem>
            <Center>
              <Digit
                value={hours}
                name="hoursBudgetLimitTimer"
                isRunning={isRunning}
                disabled
                small
              />
              <Text fontSize="0.6vw"> : </Text>
              <Digit
                value={minutes}
                name="minutesBudgetLimitTimer"
                isRunning={isRunning}
                min="0"
                max="59"
                disabled
                small
              />
              <Text fontSize="0.6vw"> : </Text>
              <Digit
                value={seconds}
                name="secondsBudgetLimitTimer"
                isRunning={isRunning}
                min="0"
                max="59"
                disabled
                small
              />
            </Center>
          </WrapItem>

          <WrapItem>
            <Text fontSize="0.6vw">tijd verstreken.</Text>
          </WrapItem>
        </Wrap>
      </div>
    </Center>
  );
}

BudgetLimitTimer.propTypes = {
  timerColor: PropTypes.string,
  timerStopTimestamp: PropTypes.string,
  seconds: PropTypes.number.isRequired,
  minutes: PropTypes.number.isRequired,
  hours: PropTypes.number.isRequired,
  isRunning: PropTypes.bool,
  isOutOfBudget: PropTypes.bool,
};
