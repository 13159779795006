import { useGqlInfiniteQuery, useGqlQuery } from '../useGqlQuery';
import { GET_CLIENTS, GET_CLIENT_TASKS } from './gqlClients';

export const useClients = (variables = {}, config = {}) => {
  const { isLoading, isError, data, ...props } = useGqlInfiniteQuery(
    variables ? ['clients', variables] : ['clients'],
    GET_CLIENTS,
    variables,
    config,
  );

  return { isLoading, isError, clients: data, ...props };
};

export const useClientTasks = (identifier, config = {}) => {
  const { isLoading, isError, data } = useGqlQuery(
    ['clientTasks', identifier],
    GET_CLIENT_TASKS,
    {
      id: identifier,
    },
    config,
  );

  return { isLoading, isError, tasks: data };
};
