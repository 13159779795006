import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useController } from 'react-hook-form';
import ControlledMultiSelect from '../select/ControlledMultiSelect';
import { useStore } from '../../store';

export default function RecentTasks({ control, recentTasks }) {
  const { setTask } = useStore((state) => ({
    setTask: state.setTask,
  }));

  const {
    field: { value },
  } = useController({
    name: 'recentTask',
    control,
  });

  useEffect(() => {
    if (!value) return;
    const selectedTask = recentTasks?.find(({ id }) => id === value);

    if (selectedTask) {
      setTask(selectedTask);
    }
  }, [value]);

  return (
    <ControlledMultiSelect
      id="recentTask"
      name="recentTask"
      label="Recente taken"
      control={control}
    >
      {recentTasks ? (
        <>
          {recentTasks?.map(({ id, name, client }) => (
            <option key={id} value={id} style={{ paddingInline: 15, paddingBlock: 8 }}>
              {client?.name}
              {' - '}
              {name}
            </option>
          ))}
        </>
      ) : (
        <option disabled style={{ paddingInline: 15, paddingBlock: 8 }}>
          Geen recente taken gevonden
        </option>
      )}
    </ControlledMultiSelect>
  );
}

RecentTasks.propTypes = {
  control: PropTypes.object,
  recentTasks: PropTypes.arrayOf(PropTypes.object),
};
