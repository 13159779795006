import { useGqlQuery } from '../useGqlQuery';
import { GET_RECENT_GITHUB_COMMITS } from '../users/gqlUsers';

export const useGithubCommits = (config = {}) => {
  const { isLoading, isFetching, isError, data, refetch } = useGqlQuery(
    ['commits'],
    GET_RECENT_GITHUB_COMMITS,
    {},
    config,
  );

  const commits = data?.recent_commits ?? [];

  return { isLoading, isFetching, isError, commits, refetch };
};
