import Date from '../utils/date';
import Time from '../utils/time';
import { createUid } from '../utils/helper';

const createTimelogsSlice = (set, get) => ({
  timelog: null,
  timelogs: [],
  getTimelogsByDate: (date) => {
    const formattedDate = Date.getFormattedDateYearMonthDay(date);

    // Get unspecified tasks by date from store
    const unspecifiedTimelogs = get().unspecifiedTimelogs[formattedDate] || [];

    return [...unspecifiedTimelogs];
  },
  setTimelogsByDate: (date) => {
    set({ timelogs: get().getTimelogsByDate(date) });
  },
  setTimelog: (timelog) => {
    set({ timelog });
  },
  unsetTimelog: () => {
    set({ timelog: null });
  },
  updateTimelog: ({ timelog = get().timelog, data = {} }) => {
    set((state) => ({
      timelogs: state.timelogs.map((item) => {
        if (item?.id !== timelog?.id) {
          return item;
        }

        const {
          hours = item?.hours,
          minutes = item?.minutes,
          seconds = item?.seconds,
          client = item?.client,
          task = item?.task || get().task,
        } = data;

        let { time } = item;
        const { comments } = item;

        if (hours || minutes) {
          time = Time.getMinutesFromTime({ hours, minutes, seconds: seconds && 0 });
        }

        // make sure there is an actual task selected
        const updatedTask = {
          ...task,
          ...(client && { client: { ...client } }),
        };

        return {
          ...item,
          ...timelog,
          comments: comments || timelog?.comments,
          ...data,
          ...{ time },
          ...(updatedTask && Object.keys(updatedTask).length > 0 && { task: { ...updatedTask } }),
        };
      }),
    }));
  },
  prependTimelog: ({ includeTask = true, data = {} }) => {
    const { task = {}, hours = 0, minutes = 0, seconds = 0, comments = '' } = data;

    const newTimelogTask = includeTask ? { ...get().task, ...task } : null;

    const timelog = {
      id: createUid(),
      time: 0,
      hours,
      minutes,
      seconds,
      date: Date.getFormattedDate(),
      comments,
      task: newTimelogTask,
    };

    const timelogs = [timelog, ...get().timelogs];

    set(() => ({ timelogs }));
    get().setTimelog(timelog);
  },
  deleteTimelog: (id) => {
    set((state) => ({
      timelogs: state.timelogs.filter((item) => item.id !== id),
    }));
    get().unsetTimelog();
    get().unsetTask();
    get().updateUnspecifiedTimelogs();
  },
});

export default createTimelogsSlice;
