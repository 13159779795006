import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useStore } from '../store';

import Time from '../utils/time';

export default function useUrlParams() {
  const [timeParam, setTimeParam] = useState(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const { setDate } = useStore((state) => ({
    setDate: state.setDate,
  }));

  const getSearchParam = (param) => searchParams.get(param);
  const hasSearchParam = (param) => searchParams.has(param);
  const deleteSearchParam = (param, callback) => {
    searchParams.delete(param);
    setSearchParams(searchParams);

    if (callback) {
      callback();
    }
  };

  useEffect(() => {
    // If date query param is found, set app date
    if (hasSearchParam('date')) {
      setDate(getSearchParam('date'));
    }

    // Set time
    if (hasSearchParam('time')) {
      const formattedTime = Time.getTimeFromMinutes(getSearchParam('time'));
      setTimeParam(formattedTime);
    }
  }, [searchParams]);

  return {
    getSearchParam,
    hasSearchParam,
    deleteSearchParam,
    timeParam,
    setTimeParam,
  };
}
