import dayjs from 'dayjs';

const dailyTimeQuota = 480;
const secondInterval = 1000; // update for development testing

export default class Time {
  static getTimeFromSeconds(secs) {
    const totalSeconds = Math.ceil(secs);
    const hours = Math.floor(totalSeconds / (60 * 60));
    const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
    const seconds = Math.floor(totalSeconds % 60);

    return {
      seconds,
      minutes,
      hours,
    };
  }

  static getTimeFromMinutes(minutes) {
    return this.getTimeFromSeconds(minutes * 60);
  }

  static getMinutesFromTime({ hours, minutes, seconds = 0 }) {
    const extraMinutes = seconds > 30 ? 1 : 0;
    return minutes + hours * 60 + extraMinutes;
  }

  static getFormattedTimeFromMinutes(minutes, formatValues = ['hours', 'minutes']) {
    const time = this.getTimeFromSeconds(minutes * 60);
    return formatValues.map((value) => this.getTwoDigitNumberFromValue(time[value])).join(':');
  }

  static getTwoDigitNumberFromValue(value) {
    const leftDigit = value >= 10 ? value.toString() : '0';
    const rightDigit = value >= 10 ? '' : value.toString();
    return leftDigit + rightDigit;
  }

  static getSecondsFromExpiry(expiry, shouldRound = true) {
    const now = dayjs().valueOf();
    const milliSecondsDistance = expiry - now;
    if (milliSecondsDistance > 0) {
      const val = milliSecondsDistance / secondInterval;
      return shouldRound ? Math.round(val) : val;
    }
    return 0;
  }

  static getSecondsFromPrevTime(prevTime, shouldRound = true) {
    const now = dayjs().valueOf();
    const milliSecondsDistance = now - prevTime;
    if (milliSecondsDistance > 0) {
      const val = milliSecondsDistance / secondInterval;
      return shouldRound ? Math.round(val) : val;
    }
    return 0;
  }

  static getPercentageBasedOnWorkingHours(value) {
    return (value / dailyTimeQuota) * 100;
  }

  static getRemainingTimeInHours(end, time) {
    return end - time > 0 ? Math.round((end - time) / 60) : 0;
  }

  static getOffsetTimestamp({ hours, minutes, seconds }) {
    return dayjs().add(hours, 'hour').add(minutes, 'minute').add(seconds, 'second');
  }
}
