import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { useGraphQLClient } from '../hooks/useGraphQLClient';

export const useGqlQuery = (key, query, variables, config = {}) => {
  const { graphQLClient } = useGraphQLClient();
  const fetchData = async () => graphQLClient.request(query, variables);

  return useQuery({
    queryKey: key,
    queryFn: fetchData,
    ...config,
  });
};

export const useGqlInfiniteQuery = (key, query, variables, config) => {
  const { graphQLClient } = useGraphQLClient();
  const queryName = [...key][0];

  const fetchData = async ({ pageParam = 1 }) =>
    graphQLClient.request(query, {
      page: pageParam,
      ...variables,
    });

  return useInfiniteQuery({
    queryKey: key,
    queryFn: fetchData,
    select: (data) => {
      let dataKeyData = {
        [queryName]: data.pages.map((page) => page[queryName].data).flat(1),
      };

      if (config.reversed) {
        dataKeyData[queryName].reverse();
      }

      if (config.transform) {
        dataKeyData = config.transform(dataKeyData);
      }

      return dataKeyData;
    },
    getNextPageParam: (lastPage) => {
      const paginator = lastPage[queryName].paginatorInfo;
      const { currentPage } = paginator;

      if (currentPage === paginator.lastPage) {
        return undefined;
      }

      return currentPage + 1;
    },
    getPreviousPageParam: (lastPage) => {
      const FIRST_PAGE = 1;

      const paginator = lastPage[queryName].paginatorInfo;
      const { currentPage } = paginator;

      if (currentPage === paginator.lastPage || currentPage === FIRST_PAGE) {
        return undefined;
      }

      return currentPage - 1;
    },
    ...config,
  });
};

export const useGqlMutation = (key, query, config = {}) => {
  const { graphQLClient } = useGraphQLClient();
  const fetchData = async (variables) => graphQLClient.request(query, variables);

  return useMutation({
    mutationFn: (variables) => fetchData(variables),
    mutationKey: key,
    ...config,
  });
};
