import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tag, Wrap } from '@chakra-ui/react';

// Colors from
// https://chakra-ui.com/docs/styled-system/theme
const tags = [
  {
    label: 'inschatting',
    color: 'green',
  },
  {
    label: 'support',
  },
  {
    label: 'nieuw',
  },
  {
    label: 'bug esign',
    color: 'red',
  },
  {
    label: 'bug extern',
    color: 'red',
  },
];

export default function TagList({ onChange }) {
  const [selectedTag, setSelectedTag] = useState('');

  useEffect(() => {
    onChange(selectedTag);
  }, [selectedTag]);

  return (
    <Wrap spacing={2} justify="flex-start">
      {tags.map(({ label, color }) => (
        <Tag
          key={label}
          size="sm"
          variant="solid"
          colorScheme={color ?? 'gray'}
          onClick={() => setSelectedTag(label)}
          cursor="pointer"
        >
          {label}
        </Tag>
      ))}
    </Wrap>
  );
}

TagList.propTypes = {
  onChange: PropTypes.func,
};

TagList.defaultProps = {
  onChange: () => {},
};
