import dayjs from 'dayjs';

import Date from '../utils/date';

const createActiveDateSlice = (set, get) => ({
  date: dayjs(),
  getFormattedDate: () => Date.getFormattedDate(get().date),
  setDate: (date) => {
    const formattedDate = dayjs(date);
    if (!formattedDate.isValid()) {
      return;
    }

    get().updateUnspecifiedTimelogs();
    get().unsetTimelog();
    get().unsetTask();
    get().setTimelogsByDate(formattedDate);
    // get().updateRegisteredTime();
    set({ date: formattedDate });
  },
  registeredTime: 0,
  updateRegisteredTime: (totalTime) => {
    set({ registeredTime: totalTime });
  },
});

export default createActiveDateSlice;
